
import { useState, useEffect } from 'react'
import * as React from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import {
    makeStyles,
    Grid,
    Button,
    CssBaseline,
    Paper,
    Typography,
  } from "@material-ui/core";
import Backdrop from '../../components/Backdrop';
import Logo from "../../assets/Logo400x120.png";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from '../../api';
import HomeBackground from "../../assets/fondo.png";

const Register = () => {
    const [form, setForm] = useState({
        nombres: '',
        apellidos: '',
        documento: '',
        email: '',
        telefono: '',
        cargo: '',
        password: "",
        confirmPassword: "",
    });
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const validate = e.currentTarget;
        if (validate.checkValidity() === false || form.password !== form.confirmPassword) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setLoading(true);
            axios.post(`/user/register`, form).then(({ data }) => {
                if (data.userCreated?.success) {
                    Swal.fire({
                        icon: "success",
                        text: "Se ha creado el usuario correctamente.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    history("/");
                } else {
                    Swal.fire({
                        icon: "error",
                        text: data?.userCreated?.message,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
                setLoading(false);
            });
        }
    }

    const handleInput = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const soloNumeros = (e) => {
        const key = e.charCode;
        if (!(key >= 48 && key <= 57)) {
            e.preventDefault();
        }
    }

    useEffect(() => {
        console.log(form)
    }, [form]);

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} md={7} className={classes.image}>
            </Grid>
            <Grid item xs={12} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <div className={classes.containerLogo}>
                        <img alt="logo" src={Logo} />
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                component="h1"
                                variant="h4"
                                style={{ fontWeight: "bolder", letterSpacing: "-1px", color: "#032f3c" }}
                                className="text-start"
                            >
                                Registro de Usuario
                            </Typography>
                        </Grid>
                    </Grid>
                    <Form className={classes.form} onSubmit={handleSubmit} noValidate validated={validated}>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6">
                                <FloatingLabel label="Nombres *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="nombres"
                                        variant="outlined"
                                        className="rounded-3"
                                        autoComplete="nombre"
                                        onChange={handleInput}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Los nombres son obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <FloatingLabel label="Apellidos *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        type="text"
                                        name="apellidos"
                                        variant="outlined"
                                        className="rounded-3"
                                        autoComplete="apellido"
                                        onChange={handleInput}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Los Apellidos son obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6">
                                <FloatingLabel
                                    label="Documento *"
                                    className="mb rounded-3"
                                >
                                    <Form.Control
                                        required
                                        type="text"
                                        name="documento"
                                        variant="outlined"
                                        className="rounded-3"
                                        autoComplete="documento"
                                        onChange={handleInput}
                                        onKeyPress={(e) => { soloNumeros(e) }}
                                        maxLength="10"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        El Documento es Obligatorio
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <FloatingLabel
                                    label="Teléfono"
                                    className="mb rounded-3"
                                >
                                    <Form.Control
                                        type="text"
                                        name="telefono"
                                        variant="outlined"
                                        className="rounded-3"
                                        autoComplete="telefono"
                                        onChange={handleInput}
                                        onKeyPress={(e) => { soloNumeros(e) }}
                                        maxLength="10"
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="7">
                                <FloatingLabel label="Correo Electrónico *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        autoComplete="email"
                                        className="rounded-3"
                                        onChange={handleInput}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        El corre electronico es obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} md="5">
                                <FloatingLabel label="Cargo *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        name="cargo"
                                        type="text"
                                        variant="outlined"
                                        autoComplete="cargo"
                                        className="rounded-3"
                                        onChange={handleInput}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        El cargo es obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="12">
                                <FloatingLabel label="Contraseña *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        autoComplete="password"
                                        className="rounded-3"
                                        onChange={handleInput}
                                        isInvalid={form.password.length < 6}
                                        isValid={form.password.length >= 6}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        La contraseña es obligatoria.
                                        La contraseña como minimo debe tener 6 caracteres.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="12">
                                <FloatingLabel label="Confirmar Contraseña *" className="mb rounded-3">
                                    <Form.Control
                                        required
                                        name="confirmPassword"
                                        type="password"
                                        variant="outlined"
                                        autoComplete="password"
                                        className="rounded-3"
                                        onChange={handleInput}
                                        isInvalid={form.password != form.confirmPassword}
                                        isValid={form.password == form.confirmPassword}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Confirmar la contraseña es obligatorio.
                                        Los 2 campos de contraseña deben coincidir.
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                        <Row className="justify-content-between mx-1">
                            <Button
                                variant="contained"
                                className={classes.buttonBack}
                                onClick={() => history("/")}
                            >
                                cancelar
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                className={classes.submit}
                            >
                                Registrar
                            </Button>
                        </Row>
                    </Form>
                </div>
            </Grid>
            <Backdrop open={loading} />
        </Grid>
    )
}
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: `url(${HomeBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        margin: theme.spacing(16, 6),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(17, 6),
        },
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        width: "13em",
        color: "#fff",
        fontWeight: "bolder",
        backgroundColor: "#d3c211",
        "&:hover": {
            backgroundColor: "#ab9d13"
        },
        margin: theme.spacing(2, 0, 2),
    },
    buttonBack: {
        width: "13em",
        color: "#fff",
        fontWeight: "bolder",
        backgroundColor: "#1b4a61",
        "&:hover": {
            backgroundColor: "#032f3c"
        },
        margin: theme.spacing(2, 0, 2),
    },
    container__input_root: {
        borderRadius: "10px",
    },
    containerLogo: {
        margin: "-3em 0 3em 0",
    },
    textField: {
        margin: "11em 1em 0 1em",
        color: theme.palette.background.main,
        fontWeight: "bolder",
        fontSize: "42px",
        textShadow: "2px 2px 2px black",
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
}));

export default Register
