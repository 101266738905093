import React from 'react'
import { connect } from 'react-redux';
import axios from '../../api';
import { Header, CardCustom } from '../../components'
import AppointmentManage from './AppointmentManage';
import { loginRequest } from "../../actions";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SelectSpots = (props) => {
    const [spots, setSpots] = React.useState([]);
    const [spot, setSpot] = React.useState({});
    const { token, permissionManagement, permissionBooking, loginRequest, user } = props;
    const history = useNavigate();

    const getSpots = async () => {
        const { data } = await axios.post(
            `/spot/getSpots`,
            {},
            {
                headers: { "access-token": token },
            }
        );
        setSpots(data?.spots);
    };

    React.useEffect(async () => {
        if (permissionBooking.includes(1) || permissionManagement.includes(1)) {
            const _user = await getUser();
            if (!_user) {
                Swal.fire({
                    icon: "error",
                    text: "Error al validar usuario.",
                    showConfirmButton: false,
                    timer: 3000,
                });
                loginRequest();
                history("/");
                return;
            }
            getSpots();
        } else {
            history("/");
            window.location.reload();
        }
    }, []);

    const getUser = async () => {
        try {
            const result = await axios.post(`/user/getUsers`, {
                id: user.id,
            },
                {
                    headers: {
                        "access-token": token,
                    },
                }).catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: "Error al validar usuario.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    if (error.mensaje === "Invalid Token") {
                        loginRequest();
                        history("/");
                    }
                });
            return result?.data?.users[0];
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {
                Object.keys(spot).length === 0 && (
                    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                        <Header category="ESPACIOS" title="Selector de Espacio" />
                        <div className="container">
                            <div className="row justify-content-start">
                                {
                                    spots.map(item =>
                                    (<CardCustom
                                        key={item.id}
                                        isSpot
                                        title={item.nombre}
                                        capacity={item.capacidad}
                                        details={item.descripcion}
                                        url={`${axios.defaults.baseURL}/${item.url_imagen}`}
                                        onClick={() => { setSpot(item) }}
                                    />))
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            {
                Object.keys(spot).length > 0 && (
                    <AppointmentManage currentSpot={spot} />
                )
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        token: state.token,
        userId: state.user?.id,
        groupId: state.user?.id_grupos_usuarios,
        permissionManagement: (state.permission || [])
            .filter((data) => data.modulosAcciones?.id_modulos === 10)
            .map((item) => item.modulosAcciones?.id_acciones),
        permissionBooking: (state.permission || [])
            .filter((data) => data.modulosAcciones?.id_modulos === 11)
            .map((item) => item.modulosAcciones?.id_acciones),
    };
};

const mapDispatchToprops = {
    loginRequest,
};

export default connect(mapStateToProps, mapDispatchToprops)(SelectSpots);