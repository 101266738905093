import React from "react";
import { AiOutlineBank } from "react-icons/ai";
import {
  BiBox,
  BiCalendar,
  BiGridAlt,
  BiUserPin,
  BiUserPlus,
} from "react-icons/bi";
import { IoMdContacts } from "react-icons/io";
import { GrInsecure, GrCatalogOption } from "react-icons/gr";
import { FaUserEdit } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { MdRestore } from "react-icons/md";
import axios from "../api";
import aprobado from "../assets/iconosEstado/01-Aprobado.png";
import entregado from "../assets/iconosEstado/01-Entregado.png";
import pendiente from "../assets/iconosEstado/01-Pendiente.png";
import cancelado from "../assets/iconosEstado/01-Rechazado.png";
import observacion from "../assets/iconosEstado/01-Observacion.png";

const GridImage = (props) => (
  <div className="flex items-center gap-2 mx-auto" style={{ width: "7rem" }}>
    <img
      className="rounded-full w-20 h-20"
      src={`${axios.defaults.baseURL}/${props.url_imagen}`}
      alt="Espacio"
    />
    <p className="text-center">{props.nombre}</p>
  </div>
);

const stock = (props) => (
  <div>
    <p>{props.item.cantidad_reservada}</p>
    {props.item.estado == 3 ?
      <div
        className="p-1 rounded border border-secondary justify-content-center"
        /* className="p-1 rounded" */
        style={{ cursor: "pointer", display: 'flex', }}
        onClick={() => props.stock(props.item)}
      >
        <img src={entregado} width="25" style={{ padding: "4px" }} />
        <p className="p-1">
          stock
        </p>
      </div>
      :
      null
    }
  </div>
);

const participantes = (props) => (
  <div>
    <p>{props.item.reserva_participante.length}</p>

    <div
      className="p-1 rounded border border-secondary justify-content-center"
      /* className="p-1 rounded" */
      style={{ cursor: "pointer", display: 'flex', }}
      onClick={() => props.openParticipant(props.item)}
    >
      <img src={entregado} width="25" style={{ padding: "4px" }} />
      <p className="p-1">
        lista de asistencia
      </p>
    </div>

  </div>
);

const state = (props) => {
  let status = ""
  switch (props.item.estado) {
    case 1:
      status = "Gestionar";
      break;
    case 2:
      status = "Entregar"
      break;
    case 3:
      status = "Devolver";
      break;
  }
  return (
    <>
      {
        props.item.estado === 3 && (
          <div className="p-1 rounded border border-secondary mb-2 justify-content-center"
            style={{
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => props.observations(props.item)} >
            <img
              src={
                observacion
              }
              width="25"
              style={{ padding: "4px" }}
            />
            <p className="p-1">
              Observaciones
            </p>
          </div>
        )
      }
      <div
        className="p-1 rounded border border-secondary justify-content-center"
        /* className="p-1 rounded" */
        style={{
          cursor: "pointer",
          display: "flex",
        }}
        onClick={() => props.modal(props.item)}
      >
        <img
          src={
            props.item.estado == 1
              ? pendiente
              : props.item.estado == 2
                ? aprobado
                : entregado
          }
          width="25"
          style={{ padding: "4px" }}
        />
        <p className="p-1">
          {status}
        </p>
      </div>
      {
        props.item.estado === 2 && (
          <div className="p-1 rounded border border-secondary mt-2 justify-content-center"
            style={{
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => props.cancel(props.item)} >
            <img
              src={
                cancelado
              }
              width="25"
              style={{ padding: "4px" }}
            />
            <p className="p-1">
              Cancelar
            </p>
          </div>
        )
      }
    </>
  );
};

const status = (props) => {
  let estado = ""
  switch (props.item.estado) {
    case -1:
      estado = "Cancelado";
      break;
    case 1:
      estado = "Por aprobar";
      break;
    case 2:
      estado = "Aprobado";
      break;
    case 3:
      estado = "Entregado";
      break;
    case 4:
      estado = "Devuelto";
      break;
  }
  return (
    <>
      {
        estado
      }
    </>
  );
};

const formatDates = (props) => {
  let d = new Date(
    props?.fecha_sesion?.replace("T", " ").replace("Z", " ") ||
    props?.fecha?.replace("T", " ").replace("Z", " ")
  ),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;

  if (day.length < 2) day = "0" + day;

  let hour = d.getHours();
  let minutes = d.getMinutes();

  if (hour < 10) hour = "0" + hour;

  if (minutes < 10) minutes = "0" + minutes;

  const dates = [year, month, day].join("-");
  const hours = [hour, minutes].join(":");
  return `${dates} ${hours}`;
};

export const reportLudicAppointmentGrid = [
  {
    field: "recurso",
    headerText: "Recurso",
    width: "100",
    textAlign: "center",
  },
  {
    field: "fechaInicio",
    headerText: "Fecha Inicio",
    width: "75",
    textAlign: "center",
  },
  {
    field: "fechaFin",
    headerText: "Fecha Fin",
    width: "75",
    textAlign: "center",
  },
  {
    field: "existencia",
    headerText: "Stock Entregado",
    width: "75",
    textAlign: "center",
  },
  {
    field: "solicitante",
    headerText: "Solicitante",
    width: "135",
    textAlign: "center",
  },
  {
    field: "estado",
    headerText: "Estado",
    width: "75",
    textAlign: "center",
  },
  {
    field: "funcionario",
    headerText: "Entregado por",
    width: "135",
    textAlign: "Left",
  },
];

export const reportSpotsGrid = [
  {
    field: "espacio",
    headerText: "Espacio",
    width: "135",
    textAlign: "Left",
  },
  {
    field: "funcionario",
    headerText: "Entregado por",
    width: "135",
    textAlign: "Left",
  },
  {
    field: "fechaInicio",
    headerText: "Fecha Inicio",
    width: "135",
    textAlign: "Left",
  },
  {
    field: "fechaFin",
    headerText: "Fecha Fin",
    width: "135",
    textAlign: "Left",
  },
  {
    field: "solicitante",
    headerText: "Solicitante",
    width: "135",
    textAlign: "Left",
  },
  {
    field: "estado",
    headerText: "Estado",
    width: "75",
    textAlign: "Left",
  },
];

export const logsGrid = [
  {
    field: "registro",
    headerText: "Registro",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "usuario.email",
    headerText: "Usuario",
    width: "100",
    textAlign: "Left",
  },
  {
    field: "modulo.nombre",
    headerText: "Modulo",
    width: "75",
    textAlign: "Left",
  },
  {
    template: formatDates,
    headerText: "Fecha",
    width: "70",
    textAlign: "Left",
  },
  {
    field: "ip",
    headerText: "IP",
    width: "100",
    textAlign: "Left",
  },
];

export const permissionsGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "grupoUsuarios.nombre",
    headerText: "Grupo de Usuario",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "modulosAcciones.modulos.nombre",
    headerText: "Módulo",
    width: "135",
    textAlign: "Center",
  },
];

export const moduleGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "modulos.nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
];

export const userGroupsGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
];

export const RequestSpotGrid = [
  {
    field: "item.espacio.nombre",
    headerText: "Espacio",
    width: "110",
    textAlign: "Center",
  },
  {
    field: "item.nombre",
    headerText: "Detalle",
    width: "130",
    textAlign: "Center",
  },
  {
    field: "formatfecha",
    headerText: "Horario reserva",
    width: "100",
    textAlign: "Center",
  },
  {
    template: participantes,
    headerText: "Personas a Ingresar",
    width: "140",
    textAlign: "Center",
  },
  {
    template: status,
    headerText: "Estado",
    width: "90",
    textAlign: "Center",
  },
  {
    template: state,
    headerText: "Solicitud",
    width: "135",
    textAlign: "Center",
  },
];

export const ResoucesRequestGrid = [
  {
    field: "item.recurso.espacio.nombre",
    headerText: "Espacio",
    width: "110",
    textAlign: "Center",
  },
  {
    field: "item.recurso.nombre",
    headerText: "Recurso",
    width: "110",
    textAlign: "Center",
  },
  {
    field: "item.nombre",
    headerText: "Detalle",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "formatfecha",
    headerText: "Horario reserva",
    width: "100",
    textAlign: "Center",
  },
  {
    template: stock,
    headerText: "Cantidad reservada",
    width: "100",
    textAlign: "Center",
  },
  {
    template: status,
    headerText: "Estado",
    width: "90",
    textAlign: "Center",
  },
  {
    template: state,
    headerText: "Solicitud",
    width: "135",
    textAlign: "Center",
  },
];

export const sessionGrid = [
  {
    template: formatDates,
    headerText: "Fecha Sesión",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "token_sesion",
    headerText: "Token",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "sesion_id",
    headerText: "Sesión",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "usuarios.email",
    headerText: "Usuario",
    width: "135",
    textAlign: "Center",
  },
];

export const userGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombres",
    headerText: "Nombre",
    width: "100",
    textAlign: "Center",
  },
  {
    field: "apellidos",
    headerText: "Apellido",
    width: "100",
    textAlign: "Center",
  },
  {
    field: "documento",
    headerText: "Documento",
    width: "90",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "Correo electrónico",
    width: "165",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "telefono",
    headerText: "Teléfono",
    width: "80",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "grupoUsuarios.nombre",
    headerText: "Grupo de usuario",
    width: "90",
    textAlign: "Center",
  },
];

export const spotsGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "50",
    headerText: "Select",
  },
  {
    headerText: "Espacio",
    width: "150",
    template: GridImage,
    textAlign: "Center",
  },
  {
    field: "capacidad",
    headerText: "Capacidad",
    width: "70",
    textAlign: "Center",
  },
  {
    field: "horas_maxima",
    headerText: "Horas Maximas",
    width: "70",
    textAlign: "Center",
  },
  {
    field: "descripcion",
    headerText: "Descripción",
    width: "200",
    textAlign: "Center",
  },
];

export const ludicGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "25",
    headerText: "Select",
  },
  {
    headerText: "Recurso",
    width: "150",
    template: GridImage,
    textAlign: "Center",
  },
  {
    field: "espacio.nombre",
    headerText: "Espacio al que pertenece",
    width: "100",
    textAlign: "Center",
  },
  {
    field: "existencias.length",
    headerText: "Stock",
    width: "50",
    textAlign: "Center",
  },
  {
    field: "horas_maxima",
    headerText: "Horas Maximas",
    width: "70",
    textAlign: "Center",
  },
  {
    field: "descripcion",
    headerText: "Descripción",
    width: "200",
    textAlign: "Center",
  },
];

export const links = [
  {
    title: "Usuarios",
    links: [
      {
        name: "Usuarios",
        url: "users",
        id: 2,
        icon: <BiUserPin size={20} />,
      },
      {
        name: "Aprobar usuarios",
        url: "userapprove",
        id: 8,
        icon: <BiUserPlus size={20} />,
      },
    ],
  },
  {
    title: "ESPACIOS",
    links: [
      {
        name: "Gestión de espacios",
        url: "spots",
        id: 9,
        icon: <AiOutlineBank size={20} />,
      },
      {
        name: "Gestión de reservas de espacios",
        url: "reserves/spots",
        id: 11,
        icon: <BiCalendar size={20} />,
      },
      {
        name: "Solicitudes",
        url: "request/spots",
        id: 10,
        icon: <BiCalendar size={20} />,
      },
    ],
  },
  {
    title: "Recursos lúdicos",
    links: [
      {
        name: "Gestión de recursos lúdicos",
        url: "ludic",
        id: 12,
        icon: <BiBox size={20} />,
      },
      {
        name: "Gestión de reservas de recursos lúdicos",
        url: "reserves/ludic",
        id: 14,
        icon: <BiCalendar size={20} />,
      },
      {
        name: "Solicitudes",
        url: "request/ludic",
        id: 13,
        icon: <BiCalendar size={20} />,
      },
    ],
  },
  {
    title: "Reportes",
    links: [
      {
        name: "Recursos Lúdicos",
        url: "reports/ludic",
        id: 15,
        icon: <GoGraph size={20} />,
      },
      {
        name: "Espacios",
        url: "reports/spot",
        id: 15,
        icon: <GoGraph size={20} />,
      },
      {
        name: "Logs",
        url: "logs",
        id: 16,
        icon: <GrCatalogOption size={20} />,
      },
    ],
  },
  {
    title: "CONFIGURACIÓN",
    links: [
      {
        name: "Grupos de usuarios",
        url: "userGroups",
        id: 1,
        icon: <IoMdContacts size={20} />,
      },
      {
        name: "Módulos",
        url: "modules",
        id: 3,
        icon: <BiGridAlt size={20} />,
      },
      {
        name: "Permisos",
        url: "permissions",
        id: 5,
        icon: <GrInsecure size={20} />,
      },
      /* {
        name: 'Parámetros',
        url: 'parameters',
        id: 6,
        icon: <TiFlowParallel />,
      }, */
      {
        name: "Sesiones",
        url: "sessions",
        id: 7,
        icon: <MdRestore size={20} />,
      },
      {
        name: "Configuración De Usuario",
        url: "profile/edit",
        icon: <FaUserEdit size={20} />,
      },
    ],
  },
];

export const weekDays = [
  {
    name: "Lunes",
    value: 1,
  },
  {
    name: "Martes",
    value: 2,
  },
  { name: "Miércoles", value: 3 },
  {
    name: "Jueves",
    value: 4,
  },
  {
    name: "Viernes",
    value: 5,
  },
  {
    name: "Sábado",
    value: 6,
  },
  {
    name: "Domingo",
    value: 0,
  },
];

export const themeColors = [
  {
    name: "blue-theme",
    color: "#032f3c",
  },
  {
    name: "green-theme",
    color: "#1b4a61",
  },
  {
    name: "purple-theme",
    color: "#d3c211",
  },
  {
    name: "red-theme",
    color: "#282828",
  },
];
