import React from 'react'
import { Button, Modal, FloatingLabel, Form, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from "../../api";

const ModalInfoUser = ({ show, setShow, token, editMode, editId, groupId, userId, viewMode, get, setLoading }) => {
    const [action, setAction] = React.useState([])
    const [modules, setModules] = React.useState([])
    const [checked, setChecked] = React.useState([])
    const [idModule, setIdModule] = React.useState('')
    const [validated, setValidated] = React.useState(false);

    const clearFields = () => {
        setChecked([]);
        setModules([]);
        setAction([]);
        setIdModule('');
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
    }

    const handleToggleChecked = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (checked.length > 0) {
                if (editMode) {
                    editUser()
                } else {
                    addUser()
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: "Seleccione al menos una acción",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    };

    const editUser = () => {
        const id_modulos = idModule
        const id_acciones = checked
        const form = {
            id_modulos,
            id_acciones
        }
        handleClose()
        setLoading(true)
        axios.put(`/moduleAction/${editId}`,
            form,
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(async () => {
                    setLoading(false);
                    if (res.data.updated) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Editado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true)
                        Swal.fire({
                            icon: "error",
                            text: res.data.updated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                    setShow(true)
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido editar.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }, 2000);

            });
    }

    const addUser = () => {
        const id_modulos = idModule
        const id_acciones = checked
        const form = {
            id_modulos,
            id_acciones
        }
        handleClose();
        setLoading(true);
        axios.post(`/moduleAction/`,
            form,
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(() => {
                    setLoading(false);
                    if (res.data.created) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Creado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true);
                        Swal.fire({
                            icon: "error",
                            text: res.data.userCreated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }, 2000);
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoading(false);
                    setShow(true);
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido crear.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }, 2000);
                console.error(error)

            });
    }

    const getActions = async () => {
        try {
            const { data } = await axios.post(
                `/action/getActions`,
                {},
                {
                    headers: { "access-token": token },
                }
            );
            setAction(data.actions);
        } catch (error) {
            console.error(error)
            Swal.fire({
                icon: "error",
                text: "Ocurrio un error al consultar las acciones.",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const getModules = async () => {
        try {
            const { data } = await axios.post(
                `/module/getModules`,
                {},
                {
                    headers: { "access-token": token },
                }
            );
            setModules(data.modules);
        } catch (error) {
            console.error(error)
            Swal.fire({
                icon: "error",
                text: "Ocurrio un error al consultar los modulos.",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const getModuleAction = async () => {
        try {// si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
            const { data } = await axios.get(`/moduleAction/${editId}`, {
                headers: { "access-token": token },
            });
            const acciones = [];
            data?.moduleAction?.map((item) => acciones.push(item.id_acciones));
            setChecked(acciones);
            setIdModule(data?.moduleAction[0]?.id_modulos)
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                text: "Error al Consultar los modulos",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    React.useEffect(() => {
        getActions()
        getModules()
        if (editId !== 0 && editId !== undefined) {
            getModuleAction()
        }
    }, [editId])

    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>{editMode ? 'Editar' : viewMode ? 'Ver' : 'Crear'} Modulo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} controlId="validationCustom08">
                            <FloatingLabel
                                controlId="grupUser"
                                label="Modulos"
                                className="mb"
                            >
                                <Form.Select onChange={(e) => setIdModule(e.target.value)} value={idModule} placeholder="Grupo de Usuario" required disabled={viewMode || editMode}>
                                    <option defaultValue></option>
                                    {
                                        modules
                                            .sort((a, b) => (a.nombre < b.nombre ? -1 : 1))
                                            .map((data) => {
                                                return (
                                                    <option key={`module-${data.id}`} value={data.id}>
                                                        {data.nombre}
                                                    </option>
                                                );
                                            })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    El modulo es obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Modal.Title>Acciones</Modal.Title>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            {action.map((data) => (

                                <Form.Check
                                    type={'checkbox'}
                                    id={data.id}
                                    disabled={viewMode}
                                    checked={checked.indexOf(data.id) !== -1}
                                    label={data.nombre}
                                    onChange={handleToggleChecked(data.id)}
                                />
                            ))}
                        </Form.Group>
                    </Row>
                    <Modal.Footer>
                        <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                            {viewMode ? 'Salir' : 'Cancelar'}
                        </Button>
                        {
                            editMode ?
                                (<Button className="btn rounded-full" variant="outline-warning" type="submit">
                                    Editar
                                </Button>)
                                :
                                viewMode ? null :
                                    (<Button className="btn rounded-full" variant="outline-success" type="submit">
                                        Registrar
                                    </Button>)
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoUser