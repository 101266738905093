import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import axios from "../../api";
import { ludicGrid } from "../../data/dummy";
import { Header,Backdrop } from "../../components";
import Swal from "sweetalert2";
import { FloatingLabel, Form } from 'react-bootstrap';
import { DeleteLudic, ModalInfoLudic, ModalViewStock } from "../../components/Modals"
import { useNavigate } from "react-router-dom";
const LudicResources = (props) => {
  const {
    userId,
    permission,
    token,
  } = props;
  const [spots, setSpots] = useState([]);
  const [editId, setEditId] = useState(0);
  const [resource, setResource] = useState({});
  const [spotSelected, setSpotSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showViewStock, setShowViewStock] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEditLudic, setShowModalEditLudic] = useState(false);
  const [showModalResiterLudic, setShowModalResiterLudic] = useState(false);
  const [ludicSelected, setLudicSelected] = useState([]);
  const [ludic, setLudic] = useState([]);
  const [ref,setRef] = useState(null);
  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await spots?.map((item) => {
      const spot = {
        Nombre: item.nombre,
        Descripcion: item.apellidos,
      };
      return spot;
    });
    await exportToExcel(arrayExcel, "Espacios");
  };

  const deleteLudicList = () => {
    if (ludicSelected.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const editLudic = () => {
    if (ludicSelected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar un espacio para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else if (ludicSelected.length === 1) {
      setEditId(ludicSelected[0].id);
      setShowModalEditLudic(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un espacio para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerLudic = () => {
    setShowModalResiterLudic(true);
  };

  

  const viewStock = () => {
    if (ludicSelected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "Debe seleccionar un recurso para poder visualisar las existencias.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else if (ludicSelected.length === 1) {
      setResource([...ludicSelected][0]);
      setShowViewStock(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un recurso para poder visualisar las existencias.",
        showConfirmButton: false,
        timer: 3000,
      });
    }

  };

  const addLudic = {
    align: "Left",
    click: registerLudic,
    disabled: !permission.includes(2),
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const EditLudic = {
    align: "Left",
    click: editLudic,
    disabled: !permission.includes(3),
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const deleteLudic = {
    align: "Left",
    click: deleteLudicList,
    disabled: !permission.includes(4),
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const stockView = {
    align: "Left",
    click: viewStock,
    disabled: !permission.includes(1) || !permission.includes(3) || !permission.includes(4),
    overflow: "None",
    prefixIcon: "e-justify",
    showTextOn: "Both",
    text: "Ver Existencias",
    tooltipText: "StockView",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = ["Search", addLudic, EditLudic, deleteLudic, excelExport, stockView];

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getSpots = async () => {
    axios.post(
      `/spot/getSpots`,
      {},
      {
        headers: { "access-token": token },
      }
    ).then(({ data }) => {
      setSpots(data?.spots);
    }).catch((error) => {
      
      console.error(error)
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al consultar los espacios.",
        showConfirmButton: false,
        timer: 3000,
      });

    });

  };

  const getLudic = async () => {
    await axios.post(`/ludicResources/getLudicResources`, { id_espacios: spotSelected },
      {
        headers: { "access-token": token },
      }
    ).then( async ({ data }) => {
      setLudic(data?.ludicResources);
      setEditId(0)
      setLudicSelected([])
      if (ref !== null) {
        await ref.clearSelection()
      }
    }).catch((error) => {
      console.error(error)
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al consultar los recursos ludicos.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(async () => {
    if (permission.includes(1)) {
      await getSpots();
    }else{
      history('/')
      window.location.reload();
    }
  }, []);

  useEffect(async () => {
    await getLudic()
  }, [spotSelected])

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setLudicSelected([...ludicSelected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const ludicSelectedFilter = ludicSelected.filter(
          (item) => item.id !== data.id
        );
        setLudicSelected(ludicSelectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !ludicSelected.includes(item.id));
        setLudicSelected([...ludicSelected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const ludicSelectedFilter = ludicSelected.filter((item) =>
          data.includes(item.id)
        );
        setLudicSelected(ludicSelectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalInfoLudic
        show={showModalResiterLudic}
        setShow={setShowModalResiterLudic}
        token={token}
        getLudic={getLudic}
        setLoading={setLoading}
        spots={spots}
      />
      <ModalInfoLudic
        show={showModalEditLudic}
        setShow={setShowModalEditLudic}
        token={token}
        editId={editId}
        getLudic={getLudic}
        setLoading={setLoading}
        spots={spots}
        editMode
      />
      <ModalViewStock
        show={showViewStock}
        setShow={setShowViewStock}
        token={token}
        ludic={ludic}
        resource={resource}
        setResource={setResource}
        userId={userId}
        getLudic={getLudic}
      />
      <DeleteLudic
        show={showModalDelete}
        setShow={setShowModalDelete}
        ludicSelected={ludicSelected}
        setLudicSelected={setLudicSelected}
        token={token}
        userId={userId}
        setLoading={setLoading}
        getLudic={getLudic}
      />

      <Header category="RECURSOS LÚDICOS" title="Gestión De Recursos Lúdicos" />
      <FloatingLabel
        controlId="idSpace"
        label="Espacio"
        className="my-3 col-3"
      >
        <Form.Select
          onChange={(e) => { setSpotSelected(e.target.value); }}
          value={spotSelected}
          placeholder="Espacio">
          <option defaultValue></option>
          {
            spots
              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
              .map((e) => (
                <option key={e.id} value={e.id}>
                  {e.nombre}
                </option>
              ))
          }
        </Form.Select>
      </FloatingLabel>
      <GridComponent
        dataSource={ludic}
        enableHover={false}
        allowPaging
        ref={(g) => setRef(g)}
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {ludicGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 12)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(LudicResources);
