import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers/index";
import { loadState, saveState } from "./utils/localStorage";

if (typeof window !== "undefined") {
  const initialState = loadState() || {
    page: 0,
    rowsPerPage: 10,
    drawer: false,
    expanded: true,
    selected: "panel1",
  };

  let composeEnhacers;
  if (process.env.NODE_ENV === "production") composeEnhacers = compose;
  else composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    initialState,
    composeEnhacers(applyMiddleware(thunk))
  );

  store.subscribe(() => {
    saveState(store.getState());
  });
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ContextProvider>
          <App />
        </ContextProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
