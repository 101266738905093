import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../api";

const ModalForgetPassword = ({ show, setShow, setToken, setLoading }) => {
  const [email, setEmail] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setEmail("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const info = {
      email,
    };
    axios
      .post("/user/forgetPassword", info)
      .then((res) => {
        setLoading(false);
        clearFields();
        if (res.data.success) {
          setToken(res.data.token);
          handleClose();
          Swal.fire({
            icon: "success",
            title: "Mensaje enviado",
            text: "Revise su bandeja de entrada.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Ha ocurrido un error. Intenta nuevamente.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Olvidaste tu contraseña</Modal.Title>         
      </Modal.Header>      
      <Modal.Body>
        <p className="mb-2 text-muted h5">Para recuperar tu cuenta ingresa tu correo electrónico y
          posteriormente se le enviará un mensaje. Si no encuentra el mensaje
          tal vez esté en la bandeja de correos no deseados (spam).</p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>Ingrese su correo electrónico para restablecer su contraseña</Form.Label> 
              <FloatingLabel
                controlId="email"
                label="Email"
                className="mb"
                md="12"
              >
                <Form.Control
                  md="12"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="email"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El Email es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded-full"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn rounded-full"
              variant="outline-success"
              type="submit"
            >
              Restaurar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalForgetPassword;
