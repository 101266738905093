import axios from "../../api";
import React from 'react'
import { Button, Modal, ListGroup, FloatingLabel, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DeleteSpots = ({ show, setShow, selectedSpots, token, userId, setSelectedSpots, getSpots, setLoading }) => {
    const handleClose = () => setShow(false);

    const modalDelete = async () => {
        Swal.fire({
            text: "¿Está seguro que desea cancelar esta reserva?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            handleClose()
            setLoading(true);
            if (result.value) {
                for (let index = 0; index < selectedSpots.length; index++) {
                    const element = selectedSpots[index];
                    await sendDelete(element.id);
                }
                setSelectedSpots([]);
                setTimeout(()=>{
                    setLoading(false);
                },2000);
                getSpots();
                Swal.fire({
                    text: "Eliminado exitosamente.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        });
    };


    const sendDelete = async (id) => {
        await axios.delete(`/spot/${id}`, {
            data: { id_usuarios: userId },
            headers: { "access-token": token },
        }).then(({ data }) => {
            if (!data.userId?.success===undefined) {
                Swal.fire({
                    text: data.userId?.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }).catch(err => {
            console.error(err);
        });

    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Espacio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {selectedSpots.map((item) =>
                    (
                        <ListGroup.Item key={item.id}>
                            <Card>
                                <img variant="top" className="rounded-full mx-5" src={`${axios.defaults.baseURL}/${item.url_imagen}`} width="100" height="100" />
                                <Card.Body>
                                    <Card.Title>{item.nombre}</Card.Title>
                                    <Card.Text>
                                        {item.descripcion}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button className="btn  rounded-full" variant="outline-danger" onClick={modalDelete}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSpots;