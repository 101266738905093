import { CardActionArea, makeStyles } from '@material-ui/core';
import React from 'react';
import Card from 'react-bootstrap/Card';

const CardCustom = (props) => {
    const classes = useStyles();
    const {
        title = "Titulo por defecto",
        details = "Descripcion por defecto",
        isSpot = false,
        url = "https://www.holidify.com/images/cmsuploads/compressed/Bangalore_citycover_20190613234056.jpg",
        capacity = 0,
        onClick = () => {
            return;
        },
    } = props
    return (
        <CardActionArea className={classes.card} onClick={onClick}>
            <Card>
                <Card.Img variant="top" alt={url} src={url} style={{width:'100%', height:350}}/>
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Subtitle className='my-2'>{isSpot ? `Capacidad del personal: ${capacity}` : `Stock: ${capacity}`}</Card.Subtitle>
                    <Card.Text>
                        {details}
                    </Card.Text>
                </Card.Body>
            </Card>
        </CardActionArea>
    )
}

const useStyles = makeStyles((theme) => ({
    card: {
        width: '32%',
        borderRadius: '0.75rem',
        height: '60%',
        margin: '0.4rem',
        textAlign: 'justify' 
    },

}));

export default CardCustom