import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "../../api";
import { Header, CardCustom } from "../../components";
import LudicAppointmentManage from "./LudicAppointmentManage";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { loginRequest } from "../../actions";

const SelectResource = (props) => {
  const [spots, setSpots] = useState([]);
  const [spot, setSpot] = useState({});
  const [ludic, setLudic] = useState([]);
  const [currentLudic, setCurrentLudic] = useState({});
  const { token, permissionManagement, permissionBooking, user, loginRequest } = props;
  const history = useNavigate();
  const getSpots = async () => {
    const { data } = await axios.post(
      `/spot/getSpots`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setSpots(data?.spots);
  };

  useEffect(async () => {
    if (permissionBooking.includes(1) || permissionManagement.includes(1)) {
      const _user = await getUser();
      if (!_user) {
        Swal.fire({
          icon: "error",
          text: "Error al validar usuario.",
          showConfirmButton: false,
          timer: 3000,
        });
        loginRequest();
        history("/");
        return;
      }
      getSpots();
    } else {
      history("/");
      window.location.reload();
    }
  }, []);

  const getUser = async () => {
    try {
      const result = await axios.post(`/user/getUsers`, {
        id: user.id,
      },
        {
          headers: {
            "access-token": token,
          },
        }).catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Error al validar usuario.",
            showConfirmButton: false,
            timer: 3000,
          });
          if (error.mensaje === "Invalid Token") {
            loginRequest();
            history("/");
          }
        });
        return result?.data?.users[0];
    } catch (error) {
      console.error(error);
    }
  };

  const getLudic = async () => {
    await axios
      .post(
        `/ludicResources/getLudicResources`,
        { id_espacios: spot.id },
        {
          headers: { "access-token": token },
        }
      )
      .then(({ data }) => {
        if(data?.ludicResources.length){
          setLudic(data?.ludicResources);
        }else{
          setTimeout(()=>{
            Swal.fire({
              icon: "error",
              text: "No se encontro recurso disponible.",
              showConfirmButton: false,
              timer: 3000,
            });
            setSpot({})
          },500);
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los recursos ludicos.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(async () => {
    if (
      Object.keys(spot).length !== 0 &&
      (permissionBooking.includes(1) || permissionManagement.includes(1))
    ) {
      await getLudic();
    }
  }, [spot]);

  return (
    <>
      {Object.keys(spot).length === 0 &&
        Object.keys(currentLudic).length === 0 && (
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="ESPACIOS" title="Selector de Espacio" />
            <div className="container">
              <div className="row  justify-content-start">
                {spots.map((item) => (
                  <CardCustom
                    isSpot
                    title={item.nombre}
                    capacity={item.capacidad}
                    details={item.descripcion}
                    url={`${axios.defaults.baseURL}/${item.url_imagen}`}
                    onClick={() => {
                      setSpot(item);
                    }}
                    key={item.id}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      {Object.keys(spot).length > 0 && Object.keys(currentLudic).length === 0 && (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <Header category="ESPACIOS" title="Selector de Recurso" />
          <div className="container">
            <div className="row justify-content-start">
              {ludic.map((item) => (
                <CardCustom
                  isSpot
                  title={item.nombre}
                  capacity={item.existencias.length}
                  details={item.descripcion}
                  url={`${axios.defaults.baseURL}/${item.url_imagen}`}
                  onClick={() => {
                    setCurrentLudic(item);
                  }}
                  key={item.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {Object.keys(spot).length > 0 && Object.keys(currentLudic).length > 0 && (
        <LudicAppointmentManage
          currentLudic={currentLudic}
          currentSpot={spot}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permissionManagement: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 10)
      .map((item) => item.modulosAcciones?.id_acciones),
    permissionBooking: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 11)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToprops = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToprops)(SelectResource);
