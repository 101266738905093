import axios from "../../api";
import React from 'react'
import { Button, Modal, ListGroup, FloatingLabel, Form, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DeleteLudic = ({ show, setShow, ludicSelected, token, userId, setLudicSelected, getLudic, setLoading }) => {
    const handleClose = () => setShow(false);

    const modalDelete = async () => {
        Swal.fire({
            text: "¿Está seguro que desea cancelar esta reserva?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            handleClose()
            setLoading(true);
            if (result.value) {
                for (let index = 0; index < ludicSelected.length; index++) {
                    const element = ludicSelected[index];
                    await sendDelete(element.id);
                }
                setLudicSelected([]);
                setTimeout(()=>{
                    setLoading(false);
                },2000);
                getLudic();
                Swal.fire({
                    text: "Eliminado exitosamente.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        });
    };


    const sendDelete = async (id) => {
        await axios.delete(`/ludicResources/${id}`, {
            data: { id_usuarios: userId },
            headers: { "access-token": token },
        }).then(({ data }) => {
            if (!data?.deleted) {
                Swal.fire({
                    text: "Hubo un error borrando el recurso lúdico",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }).catch(err => {
            console.error(err);
            Swal.fire({
                text: "Hubo un error borrando el recurso lúdico",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
            });
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Recurso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {ludicSelected.map((item) =>
                    (
                        <ListGroup.Item key={item.id}>
                            <Card>
                                <img variant="top" className="rounded-full mx-5" src={`${axios.defaults.baseURL}/${item.url_imagen}`} width="300" height="300" />
                                <Card.Body>
                                    <Card.Title>{`${item.nombre} - ${item.espacio.nombre}`}</Card.Title>
                                    <Card.Text>
                                        {item.descripcion}
                                    </Card.Text>

                                </Card.Body>
                            </Card>
                        </ListGroup.Item>
                    ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button className="btn  rounded-full" variant="outline-danger" onClick={modalDelete}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteLudic;