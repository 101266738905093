import React from "react";
import { Modal } from "react-bootstrap";
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
const BackdropComponent = ({ open }) => {
  return (
    <>
      <Modal show={open} style={{ opacity: "0.0",position: "relative", }} />
      {
        open && (
          <div className="d-flex justify-content-center" style={{alignItems: "center",}}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )
      }
    </>
  );
};

export default BackdropComponent;