import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { ResoucesRequestGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import Swal from "sweetalert2";
import { ModalAppointmantLudicInfo, StockModal, ReceiveModal } from '../../components/Modals';

const request = (props) => {
  const { token, userId, groupId, permission } = props;
  const [request, setRequest] = useState([]);
  const [open, setOpen] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalObservations, setShowModalObservations] = useState(false);
  const [ludicBookingsSelected, setLudicBookingsSelected] = useState({});
  const [ref, setRef] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (request.length > 0) {
      console.log(request);
      ref.refresh();
    }
  }, [request]);
  const toolbarOptions = ["Search"];

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const formatDate = (inicio, fin) => {
    const fInicio = new Date(inicio.replace("T", " ").replace("Z", " "));
    const fFin = new Date(fin.replace("T", " ").replace("Z", " "));

    return `
    ${fInicio.getHours() < 10 ? "0" : ""}${fInicio.getHours()}:${fInicio.getMinutes() < 10 ? "0" : ""
      }${fInicio.getMinutes()}:${fInicio.getSeconds() < 10 ? "0" : ""
      }${fInicio.getSeconds()} - ${fFin.getHours() < 10 ? "0" : ""
      }${fFin.getHours()}:${fFin.getMinutes() < 10 ? "0" : ""
      }${fFin.getMinutes()}:${fFin.getSeconds() < 10 ? "0" : ""
      }${fFin.getSeconds()}
    ${fInicio.getDate() < 10 ? "0" : ""}${fInicio.getDate()}/${fInicio.getMonth() + 1 < 10 ? "0" : ""
      }${fInicio.getMonth() + 1}/${fInicio.getFullYear()}
    `;
  };

  const getRequest = async () => {
    setLoading(true);
    await axios
      .post(
        "/ludicResourcesBooking/getLudicResourcesBooking",
        { estado: [1, 2, 3] },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        let obj = {};
        const info = [];
        data?.ludicResourcesBookings.forEach((item) => {
          item.bloques.forEach((e2) => {
            obj = {
              item: {
                ...item,
                StartTime: new Date(e2.fecha_inicio),
                EndTime: new Date(e2.fecha_fin),
                firstDate: e2.fecha_inicio,
              },
              formatfecha: formatDate(
                item.bloques[0].fecha_inicio,
                item.bloques[0].fecha_fin
              ),
              modal:
                item.estado === 1
                  ? approve
                  : item.estado === 2
                    ? reception
                    : finalize,
              observations,
              stock,
              cancel,
            };
            info.push(obj);
          });
          setRequest(info);
        });
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar las solicitudes.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const observations = (props) => {
    setLudicBookingsSelected(props);
    setShowModalObservations(true);
  };

  const approve = (props) => {
    Swal.fire({
      text: "¿Está seguro de aprobar esta reserva?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      denyButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed || result.isDenied) {
        if (result.value) {
          updateStatus(props, 2);
        } else {
          const result = await Swal.fire({
            input: "textarea",
            inputLabel: "¿Está seguro de rechazar esta reserva?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            inputPlaceholder: "Observaciones",
            inputAttributes: {
              "aria-label": "Observaciones",
            },
          });
          if (result.value !== undefined ) {
            if (result.value.length>0) {
              updateStatus(props, -1, result.value);
            }else{
              Swal.fire({
                icon: "error",
                text: 'Dese ingresar la razón del rechazo.',
                showConfirmButton: false,
                timer: 3000,
              });
            }
            
          }

        }
      }
    });
  };

  const reception = async (props) => {
    setSelected(props);
    setOpen(true);
  };

  const stock = (props) => {
    setSelected(props);
    setOpenStock(true);
  };

  const finalize = (props) => {
    Swal.fire({
      text: "¿Está seguro de Finalizar esta reserva?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        updateStatus(props, 4);
      }
    });
  };

  const cancel = (props) => {
    Swal.fire({
      text: "¿Está seguro de cancelar esta reserva?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        updateStatus(props, -1);
      }
    });
  };

  const updateStatus = async (props, status, observacion = "") => {
    let updateForm = {
      form: {
        observacion: observacion,
        estado: status,
      },
      observations: props.observaciones,
      userId,
    };
    if (status === 3) {
      updateForm.form.id_usuario_recibe = userId;
    }
    if (props.existencias_reserva) {
      updateForm.form.existencias_reserva = props.existencias_reserva;
    }
    axios
      .put(
        `/ludicResourcesBooking/${props.id}`,
        { ...updateForm },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        if (res.data.updated) {
          let message = "";
          if (props.estado) {
            switch (status) {
              case -1:
                message = "La Reserva ha sido Cancelada.";
                break;
              case 2:
                message = "La Reserva ha sido Aprobada.";
                break;
              case 3:
                message = "La Reserva ha sido Recepcionada.";
                break;
              case 4:
                message = "La Reserva ha sido Finalizada.";
                break;
            }
          } else {
            message = "Editado exitosamente.";
          }

          getRequest().then(() => {
            Swal.fire({
              icon: "success",
              text: message,
              showConfirmButton: false,
              timer: 3000,
            });
          });
          /* setTimeout(() => {
            if (observacion) {
              Swal.fire({
                text: "¿quiere agregar alguna observacion de alguno de los rescursos de la reserva?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d3c221",
                cancelButtonColor: "#282828",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
              }).then((result2) => {
                if (result2.value) {
                  console.log(request)
                  const filter = request.filter(
                    (item) => item.id === parseInt(res.data.updated)
                  );
                  console.log(filter)
                }
              });
            }
          }, 3000); */
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.updated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getRequest();
      setLoading(false);
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalAppointmantLudicInfo
        show={showModalObservations}
        setShow={setShowModalObservations}
        ludicBookingsSelected={ludicBookingsSelected}
        token={token}
        userId={userId}
        currentLudic={ludicBookingsSelected.recurso}
        // getBookings={getBookings}
        upDateObservations={updateStatus}
        editMode
        groupId={groupId}
      />
      <ReceiveModal
        open={open}
        setOpen={setOpen}
        reserve={selected}
        getReserves={getRequest}
        token={token}
        loading={loading}
        setLoading={setLoading}
      />

      <StockModal
        show={openStock}
        setShow={setOpenStock}
        selected={selected}
      />

      <Header category="Recursos Ludicos" title="Solicitudes" />
      <GridComponent
        dataSource={request}
        enableHover={false}
        allowPaging
        ref={(g) => setRef(g)}
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        allowTextWrap
      >
        <ColumnsDirective>
          {ResoucesRequestGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userId: state.user?.id,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 12)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(request);
