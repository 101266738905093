import React, { useState, useEffect } from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../api";
import moment from "moment";

const ModalAppointmantLudicInfo = ({
  show,
  setShow,
  editMode = false,
  startHourSelected,
  token,
  userId,
  currentLudic,
  ludicBookingsSelected,
  getBookings,
  upDateObservations,
  groupId,
}) => {
  const [validated, setValidated] = useState(false);
  const [startHour, setStartHour] = useState(new Date());
  const [endHour, setEndHour] = useState(new Date());
  const [day, setDay] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [repeat, setRepeat] = useState(false);
  const [repeatKind, setRepeatKind] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [observations, setObservations] = useState([]);
  const [idBooking, setIdBooking] = useState(0);
  const [cantidad, setCantidad] = useState(1);
  const [name, setName] = useState("");
  const [currentAvailability, setCurrentAvailability] = useState(0);
  const [observation, setObservation] = useState("");
  const [isClass, setIsClass] = useState(false);

  const getAvailability = async () => {
    let format = day;
    const { data } = await axios.post(
      "/ludicResourcesBooking/getLudicResourcesBooking",
      {
        fecha_inicio: moment(`${format} ${startHour}:00`).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        fecha_fin: moment(`${format} ${endHour}:00`).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_recurso: currentLudic.id,
      },
      {
        headers: {
          "access-token": token,
        },
      }
    );
    let capacidad = 0;
    data?.ludicResourcesBookings?.forEach((e) => {
      capacidad += e.cantidad_reservada;
    });
    let current = 0;
    if (!editMode) {
      current = currentLudic?.existencias?.length - capacidad;
    } else {
      current = currentLudic?.existencias?.length;

    }

    setCurrentAvailability(current);
    return current;
  };

  useEffect(() => {
    if (show) {
      getAvailability();
    }
    //eslint-disable-next-line
  }, [startHour, endHour]);

  const checkAvailability = async () => {
    let availability = await getAvailability();
    if (availability === 0 && !editMode) {
      setShow(false);
      Swal.fire({
        icon: "error",
        text: "La disponibilidad es de cero en el periodo solicitado, por favor elija otro para realizar su reserva.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    if (show) {
      checkAvailability();
    }
  }, [show]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (!editMode) {
        if (currentAvailability === 0) {
          Swal.fire({
            icon: "error",
            text: "No se puede realizar una reserva en este rango de tiempo debido a que la disponibilidad actual es de 0",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          if (cantidad <= currentAvailability) {
            addLudicBooking();
          } else {
            Swal.fire({
              icon: "error",
              text: "Por favor, digite una cantidad menor o igual a las existencias actuales",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      } else {
        upDateBooking();
      }
    }
  };

  const upDateBooking = async () => {
    await upDateObservations(
      {
        id: idBooking,
        observaciones: observations,
      },
      3
    );
    // await getBookings();
    handleClose();
  };

  const addLudicBooking = () => {
    if (!isClass) {
      const startHour2 = new Date();

      startHour2.setHours(startHour.split(":")[0]);
      startHour2.setMinutes(startHour.split(":")[1]);
      startHour2.setSeconds("00");

      const limitEndHour2 = new Date(startHour2);
      const endHour2 = new Date(startHour2);

      limitEndHour2.setHours(startHour2.getHours() + currentLudic.horas_maxima);

      endHour2.setHours(endHour.split(":")[0]);
      endHour2.setMinutes(endHour.split(":")[1]);
      endHour2.setSeconds("00");

      if (endHour2 > limitEndHour2) {
        Swal.fire({
          position: "center",
          text: `Lo máximo que se puede reservar son ${currentLudic.horas_maxima} horas`,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }

    Swal.fire({
      icon: "question",
      text: "¿Está seguro de querer realizar una reserva a las horas marcadas (Si eligió la opción repetir, esta no aplicará a los fines de semana)?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
    }).then((res) => {
      if (res.value) {
        if (repeat) {
          if (!repeatKind.trim()) {
            Swal.fire({
              icon: "error",
              text: "Debe seleccionar una frecuencia para poder realizar una reserva que se repita",
            });
          } else {
            const form = {
              startHour,
              endHour,
              day,
              date,
              repeat,
              repeatKind,
              isClass,
              name: name.trim(),
              endDate: formatDate(endDate),
              cantidad_reservada: cantidad,
            };
            axios
              .post(
                `/ludicResourcesBooking/`,
                {
                  ...form,
                  id_usuarios: userId,
                  id_recurso: currentLudic.id,
                },
                {
                  headers: {
                    "access-token": token,
                  },
                }
              )
              .then((res) => {
                if (res.data.created.success) {
                  Swal.fire({
                    icon: "success",
                    text: "Reserva creada correctamente.",
                    showConfirmButton: false,
                    timer: 3000,
                  }).then(() => {
                    Swal.fire({
                      icon: "warning",
                      text: "Todos los espacios deben ir en el orden establecido, los elementos en la misma posición; agradecemos conservar la política de orden y aseo.",
                      showConfirmButton: false,
                      timer: 3000,
                    })
                  });
                  getBookings();
                  handleClose();
                } else {
                  Swal.fire({
                    icon: "error",
                    text: res.data.created.message,
                    showConfirmButton: false,
                    timer: 3000,
                  }).then();
                }
              });
          }
        } else {
          const form = {
            startHour,
            endHour,
            day,
            date,
            repeat,
            repeatKind,
            isClass,
            name: name.trim(),
            endDate: formatDate(endDate),
            cantidad_reservada: cantidad,
          };

          axios
            .post(
              `/ludicResourcesBooking/`,
              {
                ...form,
                id_usuarios: userId,
                id_recurso: currentLudic.id,
              },
              {
                headers: {
                  "access-token": token,
                },
              }
            )
            .then((res) => {
              if (res.data.created.success) {
                Swal.fire({
                  icon: "success",
                  text: "Reserva creada correctamente.",
                  showConfirmButton: false,
                  timer: 3000,
                }).then(() => {
                  Swal.fire({
                    icon: "warning",
                    text: "Todos los espacios deben ir en el orden establecido, los elementos en la misma posición; agradecemos conservar la política de orden y aseo.",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                });
                getBookings();
                handleClose();
              } else {
                Swal.fire({
                  icon: "error",
                  text: res.data.created.message,
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            });
        }
      }
    });
  };

  const clearFields = () => {
    setDay(moment(new Date()).format().split("T")[0]);
    setDate(moment(new Date()).format().split("T")[0]);
    setRepeat(false);
    setName("");
    setRepeatKind("");
    setEndDate(moment(new Date()).format().split("T")[0]);
    setObservations([]);
    setIdBooking(0);
    setCantidad(1);
    setName("");
  };

  const handleClose = () => {
    if (!editMode) {
      clearFields();
    }
    setShow(false);
    setValidated(false);
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return [
      newDate.getFullYear(),
      padTo2Digits(newDate.getMonth() + 1),
      padTo2Digits(newDate.getDate() + 1),
    ].join("-");
  };

  const startDateEnd = (date) => {
    return new Date(date.setDate(date.getDate() + 1));
  };

  useEffect(() => {
    if (startHourSelected) {
      const initHour = startHourSelected.toLocaleTimeString("en-GB", {
        minute: "numeric",
        hour: "numeric",
      });
      if (initHour < "08:00") {
        setStartHour("08:00");
        setEndHour("08:30");
      } else if (initHour > "18:00") {
        setStartHour("17:00");
        setEndHour("18:30");
      } else {
        setStartHour(initHour);
        setEndHour(
          new Date(startHourSelected.getTime() + 30 * 60000).toLocaleTimeString(
            "en-GB",
            {
              minute: "numeric",
              hour: "numeric",
            }
          )
        );
      }
      setDay(moment(startHourSelected).format().split("T")[0]);
      setDate(moment(startHourSelected).format().split("T")[0]);
      setEndDate(
        moment(startDateEnd(startHourSelected)).format().split("T")[0]
      );
      setRepeat(false);
    }
  }, [startHourSelected]);

  useEffect(() => {
    if (
      ludicBookingsSelected !== undefined &&
      Object.keys(ludicBookingsSelected).length !== 0
    ) {
      setStartHour(
        new Date(ludicBookingsSelected.StartTime).toLocaleTimeString("en-GB", {
          minute: "numeric",
          hour: "numeric",
        })
      );
      setEndHour(
        new Date(ludicBookingsSelected.EndTime).toLocaleTimeString("en-GB", {
          minute: "numeric",
          hour: "numeric",
        })
      );
      setDay(moment(ludicBookingsSelected.firstDate).format().split("T")[0]);
      setDate(moment(ludicBookingsSelected.firstDate).format().split("T")[0]);
      setIdBooking(ludicBookingsSelected.id);
      setCantidad(ludicBookingsSelected.cantidad_reservada);
      setName(ludicBookingsSelected.nombre);

      if (ludicBookingsSelected.observaciones) {
        const obs = ludicBookingsSelected.observaciones.map((observacion) => ({
          id: observacion.id,
          id_usuarios: observacion.id_usuarios,
          fecha: new Date(observacion.fecha),
          // id_existencia_recurso: observacion.id_existencia_recurso,
          detalle: observacion.detalle,
        }));
        setObservations(obs);
      }
    }
  }, [ludicBookingsSelected]);

  const PesoFormat = (number) => {
    const operateNum = number.replaceAll(",", "").split("").reverse();
    let result = [],
      len = operateNum.length;
    for (var i = 0; i < len; i++) {
      result.push(operateNum[i]);
      if ((i + 1) % 3 === 0 && i !== len - 1) {
        result.push(",");
      }
    }

    return result.reverse().join("");
  };

  const formatObservation = () => {
    return observations
      .map((item) => `${formatDate(item.fecha)} -> ${item.detalle}`)
      .join("\n");
  };

  const addObservation = () => {
    const obj = {
      detalle: observation,
      fecha: new Date().toISOString(),
      id_usuarios: userId,
      id_reservas_espacios: idBooking,
    };
    setObservations([...observations, obj]);
    setObservation("");
  };

  const validateHours = () => {
    let status = false;
    let message = ''
    const ignoreValidate = [9, 1]
    if (!ignoreValidate.includes(groupId)) {
      if (endHour > "18:00" || startHour > "18:00") {
        status = true
        message = "La hora máxima es 06:00 p.m."
      }
      else if (endHour < "06:00" || startHour < "06:00") {
        status = true
        message = "La hora mínima es 08:00 a.m."
      } else {
        return { status, message }
      }
    }
    if (startHour >= endHour) {
      status = true,
        message = "La hora inicial debe ser menor a la final."
    }
    return { status, message }
  }

  return (
    <Modal show={show} size="xl">
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Agregar Observaciones" : "Solicitar Reserva"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel controlId="Fecha" label="Fecha" className="mb">
                <Form.Control
                  type="date"
                  value={day}
                  onChange={(e) => {
                    setDay(moment(e.target.value).format().split("T")[0]);
                  }}
                  placeholder="Fecha"
                  min={moment(new Date()).format().split("T")[0]}
                  disabled={editMode}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  La fecha de reserva es obligatoria.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Hora Inicial" className="mb">
                <Form.Control
                  type="time"
                  value={startHour}
                  onChange={(e) => {
                    setStartHour(e.target.value);
                  }}
                  placeholder="Hora Inicial"
                  disabled={editMode}
                  min="08:00"
                  max="18:00"
                  required
                  isInvalid={validateHours().status}
                />
                <Form.Control.Feedback type="invalid">
                  {validateHours().message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Hora Final" className="mb">
                <Form.Control
                  type="time"
                  value={endHour}
                  onChange={(e) => {
                    setEndHour(e.target.value);
                  }}
                  placeholder="Hora Final"
                  disabled={editMode}
                  min="08:00"
                  max="18:00"
                  required
                  isInvalid={validateHours().status}
                />
                <Form.Control.Feedback type="invalid">
                  {validateHours().message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <div className="col-3">
              <Form.Group>
                <FloatingLabel label="Descripción del evento" className="mb">
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Descripción del evento"
                    disabled={editMode}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    El nombre es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </div>
            <Col>
              <Row>
                {!editMode && (
                  <Form.Group as={Col} className="d-flex align-items-center">
                    <Form.Check
                      label="Repetir"
                      value={repeat}
                      onChange={(e) => {
                        setRepeat(e.target.checked);
                      }}
                      placeholder="Repetir"
                    />
                  </Form.Group>
                )}
              </Row>
              {
                (
                  groupId === 1 ||
                  groupId === 9 ||
                  groupId === 11 ||
                  groupId === 12 ||
                  groupId === 13 ||
                  groupId === 14 ||
                  groupId === 15 ||
                  groupId === 16 ||
                  groupId === 17 ||
                  groupId === 18 ||
                  groupId === 19 ||
                  groupId === 20 ||
                  groupId === 21
                ) && (
                  <Row>
                    <Form.Group as={Col} className="d-flex align-items-center">
                      <Form.Check
                        label="Sin restricción de tiempo"
                        value={isClass}
                        onChange={(e) => {
                          setIsClass(e.target.checked);
                        }}
                        placeholder="Sin restricción de tiempo"
                        disabled={editMode}
                      />
                    </Form.Group>
                  </Row>
                )}
            </Col>
          </Row>
          <Row className="mb-2">
            <div className="col-3">
              <Form.Group>
                <FloatingLabel
                  label={`Cantidad - Máximo: ${currentAvailability}`}
                  className="px-2"
                >
                  <Form.Control
                    type="number"
                    maxLength={`${currentAvailability}`.length}
                    value={cantidad}
                    onChange={(e) => {
                      setCantidad(PesoFormat(e.target.value));
                    }}
                    placeholder={`Cantidad - Máximo: ${currentAvailability}`}
                    disabled={editMode}
                    required
                    isInvalid={
                      parseInt(cantidad || 0) > currentAvailability ||
                      parseInt(cantidad || 0) <= 0
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {cantidad === 0
                      ? "La Cantidad es obligatoria."
                      : parseInt(cantidad || 0) <= 0
                        ? "Debe ingresar una cantidad válida"
                        : parseInt(cantidad) > currentAvailability &&
                        "La Cantidad debe ser menor a la capacidad máxima"}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </div>
          </Row>
          {repeat && (
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="validationFinalDate">
                <FloatingLabel
                  controlId="FechaFinal"
                  label="Fecha Final"
                  className="mb"
                >
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(moment(e.target.value).format().split("T")[0]);
                    }}
                    placeholder="Fecha"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La fecha final de reserva es obligatoria.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationFrequency">
                <FloatingLabel
                  controlId="Frequency"
                  label="Frecuencia"
                  className="mb"
                >
                  <Form.Select
                    onChange={(e) => setRepeatKind(e.target.value)}
                    value={repeatKind}
                    placeholder="Frecuencia"
                    required
                  >
                    <option defaultValue></option>
                    <option value="daily">Diaria</option>
                    <option value="weekly">Semanal</option>
                    <option value="monthly">Mensual</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    La frecuencia es obligatoria.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
          )}
          {editMode && (
            <Row className="mb-2">
              <Col>
                <Form.Group>
                  <FloatingLabel label="Observación" className="mb">
                    <Form.Control
                      as="textarea"
                      value={formatObservation()}
                      placeholder="Observación"
                      style={{ height: "104px" }}
                      disabled
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <FloatingLabel label="Observación" className="mb">
                      <Form.Control
                        type="text"
                        value={observation}
                        onChange={(e) => {
                          setObservation(e.target.value);
                        }}
                        placeholder="Observación"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    className="btn rounded-full"
                    variant="outline-primary"
                    onClick={addObservation}
                  >
                    {" "}
                    Agregar Comentario
                  </Button>
                </Row>
              </Col>
            </Row>
          )}
          <Modal.Footer>
            <Button
              className="btn rounded-full"
              variant="outline-dark"
              onClick={handleClose}
            >
              Salir
            </Button>
            {editMode ? (
              <>
                <Button
                  className="btn rounded-full"
                  variant="outline-success"
                  type="submit"
                >
                  Actualizar
                </Button>
              </>
            ) : (
              <Button
                className="btn rounded-full"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAppointmantLudicInfo;
