import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { reportLudicAppointmentGrid } from "../../data/dummy";
import { Header } from "../../components";
import Swal from "sweetalert2";
import { Backdrop } from "../../components"
import { FloatingLabel, Form, Row, Button } from 'react-bootstrap';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver"

const ReportLudicAppointment = (props) => {
  const { token, userId, groupId, permission } = props;
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idSpot, setIdSpot] = useState("");
  const [idLudic, setIdLudic] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [spots, setSpots] = useState([]);
  const [ludic, setLudic] = useState([]);

  const history = useNavigate();

  
  const formatDate = (date) => {
    const newDate= new Date(date.replace('T',' ').replace('Z',' '));
    return `${newDate.getDate()<10?'0':''}${newDate.getDate()}/${newDate.getMonth()+1<10?'0':''}${newDate.getMonth()+1}/${newDate.getFullYear()}
    ${newDate.getHours()<10?'0':''}${newDate.getHours()}:${newDate.getMinutes()<10?'0':''}${newDate.getMinutes()}:${newDate.getSeconds()<10?'0':''}${newDate.getSeconds()}`
  };

  const stock = (existencias) => {
    let prueba='';
    if(existencias.length<1){
      return ''
    }else{
      existencias.map((item)=>{
        prueba=prueba+item.existencia?.codigo_barras+" \n"
      })
    }    
    return prueba
  };

  const estado =(estado)=>{
    switch (estado) {
      case -1: 
      return "Cancelado"
      case 1: 
      return "Por aprobar"
      case 2: 
      return "Aprobado"
      case 3: 
      return "Entregado"
      case 4: 
      return "Devuelto"
    }
  }

  const exportExcel = async () => {
    const arrayExcel = await report?.map((item) => {
      let observaciones = ''
      item?.observaciones.forEach(element => {
        observaciones =observaciones+ `*${element.detalle}.\n`
      });
      const xlsx = {
        Recurso: item?.recurso,
        'Fecha Inicio': item?.fechaInicio,
        'Fecha Finalización': item?.fechaFin,
        Estado:item?.estado,
        'Existencia Entregada':item?.existencia,
        Solicitante: item?.solicitante,
        'Entregado por': item?.funcionario,
        Observaciones: observaciones,
      };
      return xlsx;
    });
    await exportToExcel(arrayExcel, `Reporte ${report[0]?.recurso||''}`);
  };

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "Exportar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = [excelExport];

  const getReportSpot = async () => {
    // if (idLudic !== "" && (startDate !== "" || endDate !== "")) {
      setLoading(true);
      const Where = {};
      if (idLudic.trim() !== "") {
        Where.id_recurso = idLudic;
      }
      if (startDate.trim() !== "") {
        Where.fecha_inicio = startDate;
      }
      if (endDate.trim() !== "") {
        Where.fecha_fin = endDate;
      }
      setReport([]);
      const { data } = await axios.post(
        `/ludicResourcesBooking/getLudicResourcesBookingReport`,
        Where,
        {
          headers: { "access-token": token },
        }
      );
      let obj = {}
      const info = []
      data?.ludicResourcesBookings.map((item) => {
        const dateinit= formatDate(item?.bloques[0].fecha_inicio)
        const datefin= formatDate(item?.bloques[0].fecha_fin)
        obj = {
          fechaInicio: dateinit,
          fechaFin: datefin,
          funcionario: item?.funcionario?.email,
          recurso: item?.recurso?.nombre,
          solicitante: item?.solicitante?.email,
          estado: estado(item?.estado),
          existencia:stock(item?.existencias_reserva),
          observaciones:item.observaciones,
        }
        info.push(obj)
      });
      setReport(info);
      setTimeout(()=>setLoading(false),1000);
  };

  const getLudic = async () => {
    await axios.post(`/ludicResources/getLudicResources`, { id_espacios: idSpot },
      {
        headers: { "access-token": token },
      }
    ).then(({ data }) => {
      setLudic(data?.ludicResources);
    }).catch((error) => {
      console.error(error)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consutal los recursos ludicos.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  const getSpots = async () => {
    const { data } = await axios.post(
      `/spot/getSpots`,
      {},
      {
        headers: { "access-token": token },
      }
    );
    setSpots(data.spots);
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getSpots();
      getReportSpot();
    } else {
      setTimeout(()=>setLoading(false),2000);
      history("/")
    }
  }, []);

  useEffect(() => {
    getLudic()
  }, [idSpot]);


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reportes" title="Recursos Ludicos" />
      <Row className="mb-3 d-flex justify-content-end" >
        <FloatingLabel
          controlId="idSpace"
          label="Espacio"
          className="col-2"
        >
          <Form.Select
            onChange={(e) => { setIdSpot(e.target.value); }}
            value={idSpot}
            placeholder="Espacio">
            <option defaultValue></option>
            {
              spots
                .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                .map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.nombre}
                  </option>
                ))
            }
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel
          controlId="idSpace"
          label="Recursos Ludicos"
          className="col-2"
        >
          <Form.Select
            onChange={(e) => { setIdLudic(e.target.value); }}
            value={idLudic}
            placeholder="Recursos Ludicos">
            <option defaultValue></option>
            {
              ludic
                .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                .map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.nombre}
                  </option>
                ))
            }
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel
          controlId="startDate"
          label="Fecha inicial"
          className="col-2"
        >
          <Form.Control
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Fecha inicial"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="endDate"
          label="Fecha Final"
          className=" col-2"
        >
          <Form.Control
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Fecha Final"
            min={startDate}
          />
        </FloatingLabel>
        <Button
          onClick={getReportSpot}
          variant="outline-primary"
          className="col-md-1 rounded-full"
          style={
            {
              marginLeft: '23%',
              marginRight: '1%',
            }
          }
        >
          Consultar
        </Button>
      </Row>
      <GridComponent
        dataSource={report}
        allowPaging
        toolbar={toolbarOptions}
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        allowSorting
        allowTextWrap
      >
        <ColumnsDirective>
          {reportLudicAppointmentGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 15)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(ReportLudicAppointment);
