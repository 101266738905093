import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import {userGroupsGrid } from "../../data/dummy";
import { Header } from "../../components";
import DeleteUserGroups from "../../components/Modals/DeleteUserGroups";
import ModalInfoUserGroups from "../../components/Modals/ModalInfoUserGroups";
import Swal from "sweetalert2";
import { Backdrop } from "../../components"

const UserGroup = (props) => {
  const { token, userId, groupId, permission } = props;
  const [userGroups, setUserGroups] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editId, setEditId] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRegister, setModalResiter] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await userGroups?.map((item) => {
      const xlsx = {
        Nombre: item.nombre,
      };
      return xlsx;
    });
    await exportToExcel(arrayExcel, "Grupos de Usuarios");
  };

  const deleteList = () => {
    if (selected.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const edit = () => {
    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selected.length === 1) {
      setEditId(selected[0].id);
      setShowModalEdit(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un usuario para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const register = () => {
    setModalResiter(true);
  };

  const deleteUserGroups = {
    align: "Left",
    click: deleteList,
    disabled: !permission.includes(4),
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addUserGroups = {
    align: "Left",
    click: register,
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const EditUserGroups = {
    align: "Left",
    click: edit,
    disabled: !permission.includes(3),
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = ["Search", addUserGroups, EditUserGroups, deleteUserGroups, excelExport];

  const searchOptions = {
    fields: [
      "nombre",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getUserGroups = async () => {
    setLoading(true)
    await axios.post(
      "/userGroup/getGroups",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false)
      if (groupId === 1) {
        setUserGroups(data?.groups);
      } else {
        const UserGroupsFiltered = data?.groups.filter(
          (item) => item.id_grupos_usuarios !== 1
        );
        setUserGroups(UserGroupsFiltered);
      }
      setEditId(0);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los usuarios.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getUserGroups();
      setLoading(false);
    } else {
      setLoading(false)
      history("/")
    }
  }, []);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelected([...selected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter(
          (item) => item.id !== data.id
        );
        setSelected(selectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selected.includes(item.id));
        setSelected([...selected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) =>
          data.includes(item.id)
        );
        setSelected(selectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <DeleteUserGroups
        show={showModalDelete}
        setShow={setShowModalDelete}
        selected={selected}
        userId={userId}
        token={token}
        setSelected={setSelected}
        get={getUserGroups}
        setLoading={setLoading}
      />
      <ModalInfoUserGroups
        show={showModalRegister}
        setShow={setModalResiter}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUserGroups}
        setLoading={setLoading}
      />
      <ModalInfoUserGroups
        show={showModalEdit}
        setShow={setShowModalEdit}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUserGroups}
        editMode
        editId={editId}
        setLoading={setLoading}
      />
      <Header category="Configuración" title="Grupos de Usuarios" />
      <GridComponent
        dataSource={userGroups}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {userGroupsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 1)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(UserGroup);
