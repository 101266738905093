import "./App.css";
import { useEffect } from "react";
import { Layout } from "./components"
import Register from "./pages/login/Register";
import { useStateContext } from "./contexts/ContextProvider";
import SelectResource from "./pages/ludicResource/SelectResource";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Users, UserApprove, SignIn, SpotsManage, LudicResources, ProfileSettings, Sessions, UserGroup, Module, Logs, Permissions, ReportSpots, ReportLudicAppointment, ChangePass, NotFound, Request, ResoucesRequest, SelectSpots, } from "./pages";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div>
          <Routes>
            <Route exact path="/" element={<Navigate to="/sign-in" replace={true} />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/users" element={<Layout> <Users /> </Layout>} />
            <Route exact path="/ludic" element={<Layout> <LudicResources /> </Layout>} />
            <Route exact path="/userapprove" element={<Layout> <UserApprove /> </Layout>} />
            <Route exact path="/spots" element={<Layout> <SpotsManage /> </Layout>} />
            <Route exact path="/sessions" element={<Layout> <Sessions /> </Layout>} />
            <Route exact path="/usergroups" element={<Layout> <UserGroup /> </Layout>} />
            <Route exact path="/modules" element={<Layout> <Module /> </Layout>} />
            <Route exact path="/permissions" element={<Layout> <Permissions /> </Layout>} />
            <Route exact path="/logs" element={<Layout> <Logs /> </Layout>} />
            <Route exact path="account/forget/:id" element={<ChangePass />} />
            <Route exact path="profile/edit" element={<Layout> <ProfileSettings /> </Layout>} />
            <Route exact path="/reserves/spots" element={<Layout> <SelectSpots /> </Layout>} />
            <Route exact path="/reserves/ludic" element={<Layout> <SelectResource /> </Layout>} />
            <Route exact path="/reports/spot" element={<Layout> <ReportSpots /> </Layout>} />
            <Route exact path="/reports/ludic" element={<Layout> <ReportLudicAppointment /> </Layout>} />
            <Route exact path="/request/spots" element={<Layout> <Request /> </Layout>} />
            <Route exact path="/request/ludic" element={<Layout> <ResoucesRequest /> </Layout>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
