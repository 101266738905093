import React from "react";
import { Link, NavLink,} from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import logo from "../assets/Logo400x120.png";
import { connect } from "react-redux";

const Sidebar = (props) => {
  const { permisos, user } = props;

  const modulos = permisos
    .filter((e) => e.modulosAcciones.acciones.nombre === "Leer")
    .map((e) => e.modulosAcciones.id_modulos);

  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const filterTitle = (item) => {
    let result = false;
    item.links.forEach((link) => {
      if (modulos.includes(link.id)) {
        result = true
      }
    })
    return result;
  }

  const activeLink =
    "flex items-center  pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center  pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center" style={{ marginRight: '5%' }}>
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} alt="logo Casa del Maestro" />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-3 p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          {
            links.map((item) => (
              filterTitle(item)|| item.links.filter(item=>item.id===undefined).length>0 ? (
                <div key={item.title}>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p>
                  {
                    item.links.map((link) => {
                      
                      return  link?.id ? (
                        modulos.includes(link.id) ?
                          (
                            <NavLink
                              to={`/${link.url}`}
                              key={link.name}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : '',
                              })}
                              className={({ isActive }) => (isActive ? activeLink : normalLink)}
                            >
                              {link.icon}
                              <span className="capitalize ml-3">{link.name}</span>
                            </NavLink>
                          ) : <div key={link.name}></div>
                      )
                        :
                        (
                          <NavLink
                            to={`/${link.url}`}
                            key={link.name}
                            onClick={handleCloseSideBar}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? currentColor : '',
                              borderRadius: 100,
                            })}
                            className={({ isActive }) => (isActive ? activeLink : normalLink)}
                          >
                            {link.icon}
                            <span className="capitalize ml-3">{link.name}</span>
                          </NavLink>
                        )
                          }
                    )
                  }
                </div>
              ) : <div key={item.title}></div>
            ))
          }
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    permisos: state.permission ||[],
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Sidebar);
