import React from 'react'
import { Modal, ListGroup, FloatingLabel, Form, Button, Col, Row } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import axios from "../../api";

const ModalViewStock = ({ show, setShow, resource, token, ludic, setResource, userId, getLudic }) => {
    const handleClose = () => {
        setShow(false);
    }

    const createResource = () => {
        setShow(false)
        Swal.fire({
            text: "Digite el código de barras a crear, por favor",
            input: "text",
            icon: "info",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            setShow(true)
            if (result.isConfirmed) {
                const { data } = await axios.post(`/ludicResources/existence`, {
                    codigo_barras: result.value,
                    id_recursos_ludicos: resource.id
                },
                    {
                        headers: { "access-token": token },
                    }
                );

                if (data.created.success) {
                    Swal.fire({
                        text: "Existencia de recurso creada correctamente",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    await getLudic();
                    let _resources = [...ludic].filter(item => item.id === resource.id);
                    _resources[0].existencias=[..._resources[0].existencias, data.created.id];
                    setResource(_resources[0]);
                    
                } else {
                    Swal.fire({
                        text: data.created.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            }
        });
    };

    const modalDeleteExistence = (id) => {
        Swal.fire({
            text: "¿Está seguro que desea eliminar esta existencia de recurso lúdico?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.value) {
                sendDeleteExistence(id);
            }
        });
    };

    const sendDeleteExistence = async (id) => {
        const { data } = await axios.delete(`/ludicResources/existence/${id}`, {
            data: { id_usuarios: userId },
            headers: { "access-token": token },
        });
        if (data?.deleted) {

            Swal.fire({
                text: "Eliminada exitosamente.",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
            });
            await getLudic();
            let _resources = [...ludic].filter(item => item.id === resource.id);
            const _resuercesFilter = _resources[0].existencias.filter(existencias => existencias.id !== id);
            _resources[0].existencias = _resuercesFilter;
            setResource(_resources[0]);
        } else {

            Swal.fire({
                text: "Hubo un error borrando la existencia del recurso lúdico",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Existencia de {resource.nombre}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    resource.existencias?.map((item) =>
                    (
                        <Row key={item.id} className="mb-2">
                            <ListGroup.Item as={Col}>
                                <FloatingLabel
                                    label="Codigo de Barras:"
                                    className="mb"
                                >
                                    <Form.Control type="text" value={item.codigo_barras} disabled />
                                </FloatingLabel>
                            </ListGroup.Item>
                            <Col className="align-self-center d-flex justify-content-center">
                                <Button className="align-self-center d-flex justify-content-center rounded-full" variant="outline-danger" onClick={() => { modalDeleteExistence(item.id) }}><AiOutlineDelete className="align-self-center" /></Button>
                            </Col>
                        </Row>
                    ))
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                    Salir
                </Button>
                <Button className="btn  rounded-full" variant="outline-success" onClick={createResource}
                >
                    Registrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalViewStock