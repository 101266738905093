import axios from "../../api";
import React, { useEffect, useState } from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import Swal from "sweetalert2";
import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import { AiOutlineDelete } from "react-icons/ai";

const ReceiveModal = ({
  open,
  setOpen,
  reserve,
  token,
  getReserves,
}) => {
  const handleClose = () => {
    getReserves();
    setAddedExistences([]);
    setExistences([]);
    setCurrentSelected("");
    setOpen(false);
  };

  const [existences, setExistences] = useState([]);
  const [addedExistences, setAddedExistences] = useState([]);
  const [currentSelected, setCurrentSelected] = useState("");

  useEffect(() => {
    setExistences(reserve?.recurso?.existencias);
  }, [reserve]);

  const addExistenceToReserve = () => {
    if (addedExistences.length === reserve?.cantidad_reservada) {
      Swal.fire({
        icon: "error",
        text: "No se pueden añadir más recursos que la cantidad reservada.",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (currentSelected !== "") {
        let existence = existences.filter(
          (e) => `${e.id}` === `${currentSelected}`
        )[0];
        setAddedExistences([...addedExistences, existence]);
        setCurrentSelected("");
      }
    }
  };

  const modalSubmit = async () => {
    if (addedExistences.length === reserve?.cantidad_reservada) {
      await axios.put(
        `/ludicResourcesBooking/${reserve?.id}`,
        {
          form: {
            estado: 3,
            existences: addedExistences,
          },
        },
        {
          headers: {
            "access-token": token,
          },
        }
      );
      Swal.fire({
        icon: "success",
        text: "Reserva actualizada correctamente."
      });
      getReserves();
      setOpen(false)
    } else {
      Swal.fire({
        icon: "error",
        text: "Por favor, revise las existencias añadidas, debido a que no ha colocado tantas como están en la reserva",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title>Entrega de existencias en reserva</Modal.Title>
          <p>Por favor, seleccione las existencias para poder cambiar el estado de
            reserva a entrega.</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="existenceLabel">
            Agregar existencias a reserva
          </InputLabel>
          <Select
            name="ludicSelectedResource"
            value={currentSelected}
            onChange={(e) => setCurrentSelected(e.target.value)}
            labelId="existenceLabel"
          >
            {existences?.filter((e) => !addedExistences.includes(e)).length ===
              0 && <MenuItem value="">No hay existencias disponibles</MenuItem>}
            {existences?.filter((e) => !addedExistences.includes(e)).length !==
              0 && (
                <MenuItem value="">
                  Por favor seleccione una existencia para agregar
                </MenuItem>
              )}
            {existences?.filter((e) => !addedExistences.includes(e))?.length >
              0 &&
              existences
                .filter((e) => !addedExistences.includes(e))
                .map((e) => {
                  return (
                    <MenuItem key={`existence${e.id}`} value={e.id}>
                      {e.codigo_barras}
                    </MenuItem>
                  );
                })}
          </Select>
        </FormControl>
        <Button
          className="btn rounded-full mt-2"
          variant="outline-dark"
          onClick={addExistenceToReserve}
        >
          Agregar existencia a reserva
        </Button>
        <br />
        <br />
        {addedExistences.length > 0 && (
          <>
            <h5>Existencias agregadas</h5>
            <ListGroup>
              {addedExistences.map((e) => {
                return (
                  <ListGroup.Item key={`added${e.id}`}>
                    {e.codigo_barras}
                    <Button
                      className="align-self-center d-flex justify-content-center rounded-full"
                      variant="outline-danger"
                      onClick={() => {
                        setAddedExistences([
                          ...addedExistences.filter(
                            (e2) => e2.codigo_barras !== e.codigo_barras
                          ),
                        ]);
                      }}
                    >
                      <AiOutlineDelete className="align-self-center" />
                    </Button>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn rounded-full"
          variant="outline-dark"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          className="btn  rounded-full"
          variant="outline-success"
          onClick={modalSubmit}
          disabled={addedExistences.length < reserve.cantidad_reservada}
        >
          Entregar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReceiveModal;
