import React from 'react'
import { Button, Modal, FloatingLabel, Form, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from "../../api";

const ModalInfoUser = ({ show, setShow, token, editMode, editId, groupId, userId, get, setLoading }) => {
    const [nombre, setNombre] = React.useState('')
    const [validated, setValidated] = React.useState(false);

    const clearFields = () => {
        setNombre('');
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (editMode) {
                editUser()
            } else {
                addUser()
            }
        }
    };

    const editUser = () => {
        const newUser = {
            nombre,
        }
        
        handleClose()
        setLoading(true)
        axios.put(`/userGroup/${editId}`,
            { ...newUser, userId },
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(async () => {
                    setLoading(false);
                    if (res.data.groupId) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Editado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true)
                        Swal.fire({
                            icon: "error",
                            text: res.data.userUpdated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                    setShow(true)
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido editar.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }, 2000);
                
            });
    }

    const addUser = () => {
        const newUser = {
            nombre,
        }
        handleClose();
        setLoading(true);
        axios.post(`/userGroup/`,
            { ...newUser, userId },
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(()=>{
                    setLoading(false);
                    if (res.data.groupId) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Creado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true);
                        Swal.fire({
                            icon: "error",
                            text: res.data.userCreated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                },2000);
            })
            .catch((error) => {
                setTimeout(()=>{
                    setLoading(false);
                    setShow(true);
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido crear.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                },2000);
                console.error(error)
                
            });
    }

    const getUser = async () => {
        try {// si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
            const { data } = await axios.get(`/userGroup/${editId}`, {
                headers: { "access-token": token },
            });
            setNombre(data.group?.nombre);
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                text: "Error al Consultar los datos del Usuario",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    React.useEffect(() => {
        if (editId !== 0 && editId !== undefined) {
            getUser()
        }
    }, [editId])

    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>{editMode ? 'Editar' : 'Crear'} Grupo de Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <FloatingLabel
                                controlId="nombre"
                                label="Nombre"
                                className="mb"
                                md="12"
                            >
                                <Form.Control md="12" type="text" value={nombre} onChange={(e) => { setNombre(e.target.value) }} placeholder="Nombre" required />
                                <Form.Control.Feedback type="invalid">
                                    Los nombre son obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Modal.Footer>
                        <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                            Cancelar
                        </Button>
                        {
                            editMode ?
                                (<Button className="btn rounded-full" variant="outline-warning" type="submit">
                                    Editar
                                </Button>)
                                :
                                (<Button className="btn rounded-full" variant="outline-success" type="submit">
                                    Registrar
                                </Button>)
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoUser