import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { spotsGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import Swal from "sweetalert2";
import { DeleteSpots, ModalInfoSpot } from "../../components/Modals";
const SpotsManage = (props) => {
  const { userId, permission, token } =
    props;
  const [spots, setSpots] = useState([]);
  const [editId, setEditId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEditSpot, setShowModalEditSpot] = useState(false);
  const [showModalResiterSpot, setShowModalResiterSpot] = useState(false);
  const [spotsSelected, setSpotsSelected] = useState([]);
  const [ref, setRef] = useState(null);

  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await spots?.map((item) => {
      const spot = {
        Nombre: item.nombre,
        Descripcion: item.apellidos,
      };
      return spot;
    });
    await exportToExcel(arrayExcel, "Espacios");
  };

  const deleteSpotsList = () => {
    if (spotsSelected.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const editSpot = () => {
    if (spotsSelected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un espacio para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else if (spotsSelected.length === 1) {
      setEditId(spotsSelected[0].id);
      setShowModalEditSpot(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un espacio para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerSpot = () => {
    setShowModalResiterSpot(true);
  };

  const addSpots = {
    align: "Left",
    click: registerSpot,
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Agregar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const EditSpots = {
    align: "Left",
    click: editSpot,
    disabled: !permission.includes(3),
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Editar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const deleteSpots = {
    align: "Left",
    click: deleteSpotsList,
    disabled: !permission.includes(4),
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Eliminar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "Exportar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = [
    "Search",
    addSpots,
    EditSpots,
    deleteSpots,
    excelExport,
  ];

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getSpots = async () => {
    axios
      .post(
        `/spot/getSpots`,
        {},
        {
          headers: { "access-token": token },
        }
      )
      .then(async ({ data }) => {
        setSpots(data?.spots);
        setEditId(0);
        setSpotsSelected([]);
        if (ref !== null) {
          await ref.clearSelection();
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los espacios.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    if (permission.includes(1)) {
      getSpots();
    } else {
      history("/");
      window.location.reload();
    }
  }, []);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSpotsSelected([...spotsSelected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const spotsSelectedFilter = spotsSelected.filter(
          (item) => item.id !== data.id
        );
        setSpotsSelected(spotsSelectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !spotsSelected.includes(item.id));
        setSpotsSelected([...spotsSelected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const spotsSelectedFilter = spotsSelected.filter((item) =>
          data.includes(item.id)
        );
        setSpotsSelected(spotsSelectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalInfoSpot
        show={showModalResiterSpot}
        setShow={setShowModalResiterSpot}
        token={token}
        getSpots={getSpots}
        setLoading={setLoading}
      />
      <ModalInfoSpot
        show={showModalEditSpot}
        setShow={setShowModalEditSpot}
        token={token}
        getSpots={getSpots}
        setLoading={setLoading}
        editMode
        editId={editId}
      />
      <DeleteSpots
        show={showModalDelete}
        setShow={setShowModalDelete}
        selectedSpots={spotsSelected}
        token={token}
        userId={userId}
        setSelectedSpots={setSpotsSelected}
        getSpots={getSpots}
        setLoading={setLoading}
      />
      <Header category="ESPACIOS" title="Gestión De Espacios" />
      <GridComponent
        dataSource={spots}
        enableHover={false}
        allowPaging
        ref={(g) => setRef(g)}
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {spotsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(SpotsManage);
