import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { logsGrid } from "../../data/dummy";
import { Header } from "../../components";
import Swal from "sweetalert2";
import { Backdrop } from "../../components";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const Logs = (props) => {
  const { token, userId, groupId, permission } = props;
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  
  const exportExcel = async () => {
    const arrayExcel = await sessions?.map((item) => {
      const xlsx = {
        Registro: item?.registro,
        Usuario: item?.usuario.email,
        Modulo: item?.modulo.nombre,
        IP: item?.ip,
        Fecha: item?.fecha,
      };
      return xlsx;
    });
    await exportToExcel(arrayExcel, "Logs");
  };

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    /* disabled: !permission.includes(5), */
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = [excelExport];

  const getSessions = async () => {
    setLoading(true)
    await axios.post(
      "/log/getLogs",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false);
      setSessions(data?.logs);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los Logs.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getSessions();
      setLoading(false);
    } else {
      setLoading(false)
      history("/")
    }
  }, []);


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reportes" title="Logs" />
      <GridComponent
        dataSource={sessions}
        allowPaging
        toolbar={toolbarOptions}
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        allowSorting
        allowTextWrap
      >
        <ColumnsDirective>
          {logsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 16)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(Logs);
