import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { userGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import ApproveUser from "../../components/Modals/ApproveUser";
import Swal from "sweetalert2";

const UserApprove = (props) => {
  const { token, userId, groupId, permission } = props;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await users?.map((item) => {
      const user = {
        Documento: item.documento || "",
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        "Correo electrónico": item.email,
        Teléfono: item.telefono || "",
        "Grupo de usuario": item.grupoUsuarios?.nombre,
      };
      return user;
    });
    await exportToExcel(arrayExcel, "Usuarios pendientes por aprobación");
  };

  const UsersList = () => {
    if (selectedUsers.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };


  const approveUser = {
    align: "Left",
    click: UsersList,
    disabled: !permission.includes(4),
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Aprobar",
    tooltipText: "Delete",
    type: "Button",
    visible: true,
    width: "auto",
  };


  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = ["Search", approveUser, excelExport];

  const searchOptions = {
    fields: [
      "nombres",
      "grupoUsuarios.nombre",
      "telefono",
      "email",
      "documento",
      "apellidos",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getUsers = async () => {
    setLoading(true)
    await axios.post(
      "/user/getUsers",
      {'id_grupos_usuarios':4},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false)
      setUsers(data?.users);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los usuarios.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getUsers();
      setLoading(false);
    } else {
      setLoading(false)
      history("/")
    }
  }, []);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelectedUsers([...selectedUsers, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter(
          (item) => item.id !== data.id
        );
        setSelectedUsers(selectedUsersFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selectedUsers.includes(item.id));
        setSelectedUsers([...selectedUsers, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter((item) =>
          data.includes(item.id)
        );
        setSelectedUsers(selectedUsersFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ApproveUser
        show={showModalDelete}
        setShow={setShowModalDelete}
        selectedUsers={selectedUsers}
        userId={userId}
        token={token}
        setSelectedUsers={setSelectedUsers}
        getUsers={getUsers}
        setLoading={setLoading}
      />
      <Header category="Usuarios" title="Aprobación de usuarios" />
      <GridComponent
        dataSource={users}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {userGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 8)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(UserApprove);
