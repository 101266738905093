import { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { saveAs } from "file-saver";
import { utils, write, read } from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { userGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import { DeleteUser, ModalInfoUser } from "../../components/Modals";
import Swal from "sweetalert2";
import formato from "../../assets/Usuarios.xlsx";
import "../styles/user.css";

const Users = (props) => {
  const { token, userId, groupId, permission } = props;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editId, setEditId] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRegisterUser, setModalResiterUser] = useState(false);
  const [showModalEditUser, setShowModalEditUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await users?.map((item) => {
      const user = {
        Documento: item.documento || "",
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        "Correo electrónico": item.email,
        Teléfono: item.telefono || "",
        "Grupo de usuario": item.grupoUsuarios?.nombre,
      };
      return user;
    });
    await exportToExcel(arrayExcel, "Usuarios");
  };

  const deleteUsersList = () => {
    if (selectedUsers.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const editUsers = () => {
    if (selectedUsers.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selectedUsers.length === 1) {
      setEditId(selectedUsers[0].id);
      setShowModalEditUser(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un usuario para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerUser = () => {
    setModalResiterUser(true);
  };

  const upFile = () => {
    if (!permission.includes(2)) {
      Swal.fire({
        icon: "error",
        text: "usted no cuenta con los permisos requeridos para la carga ma.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = ".xlsx"
    input.onchange = async _this => {
      let file = Array.from(input.files)[0];
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        return;
      }
      const wb = read(await file.arrayBuffer());
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = utils.sheet_to_json(ws);
      massiveUserCreation(data);
    };
    input.click();
  }

  const massiveUserCreation = (listUser = []) => {
    if (listUser.length <= 0) {
      Swal.fire({
        icon: "error",
        text: "Debe llenar la plantilla.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const schemaObj = Object.keys(listUser[0])
    if (
      !(schemaObj.includes("Nombres") &&
        schemaObj.includes("Apellidos") &&
        schemaObj.includes("Documento") &&
        schemaObj.includes("Correo Electrónico"))
    ) {
      Swal.fire({
        icon: "error",
        text: "La plantilla no tiene los campos requeridos.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const schemaObjArray = listUser.map(user => {
      if ((user.Nombres === undefined || user.Apellidos === undefined || user.Documento === undefined || user["Correo Electrónico"] === undefined)) {
        console.log(user.__rowNum__);
        Swal.fire({
          icon: "error",
          text: `El regitro en la fila ${user.__rowNum__} le faltan datos obligatorio`,
          showConfirmButton: false,
          timer: 3000,
        });
        return null;
      } else {
        if (isNaN((user.Documento + "").trim())) {
          Swal.fire({
            icon: "error",
            text: `Documento Invalido en la fila ${user.__rowNum__}.`,
            showConfirmButton: false,
            timer: 3000,
          });
          return null;
        }
        if (user["Teléfono"] === undefined) {
          user["Teléfono"] = '';
        }
        if (isNaN((user["Teléfono"] + "").trim())) {
          Swal.fire({
            icon: "error",
            text: `Numero Teléfono Invalido en la fila ${user.__rowNum__}.`,
            showConfirmButton: false,
            timer: 3000,
          });
          return null;
        }
        const newSchema = {
          nombres: user.Nombres.trim(),
          apellidos: user.Apellidos.trim(),
          documento: (user.Documento + "".trim()),
          email: user["Correo Electrónico"].trim(),
          telefono: user["Teléfono"] !== undefined ? (user["Teléfono"] + "".trim()) : '',
          id_grupos_usuarios: 3,
          row: user.__rowNum__,
        }
        return newSchema;
      }
    });

    if (schemaObjArray.includes(null)) {
      return;
    }

    const busqueda = schemaObjArray.reduce((acc, usuarios) => {
      acc[usuarios.documento] = ++acc[usuarios.documento] || 0;
      acc[usuarios.email] = ++acc[usuarios.email] || 0;
      return acc;
    }, {});

    const duplicados = schemaObjArray.filter((usuario) => {
      return busqueda[usuario.documento] || busqueda[usuario.email];
    });
    console.log(duplicados);
    if (duplicados.length > 0) {
      let message = "<spam>En la siguiente fila se encontro documento o correo electrónico duplicados:<br>";
      duplicados.forEach(item => {
        message = `${message}<b> ${item.row}. </b>Documento: ${item.documento} - Correo Electrónico: ${item.email}<br>`
      });
      message = message + '</spam>';
      Swal.fire({
        icon: "error",
        titleText: "Datos dublicados.",
        title: "Datos dublicados.",
        customClass: 'swal-wide',
        html: message,
        confirmButtonColor: "#d3c221",
      });
      return;
    }

    axios.post("/user/registerAll", {
      newUsersList: schemaObjArray,
    }, {
      headers: {
        "access-token": token,
      },
    }).then(({ data }) => {
      if (data.userCreated.length > 0) {
        if (data.userCreated[0].success) {
          Swal.fire({
            icon: "success",
            text: `Los usuarios se han creado con éxito`,
            showConfirmButton: false,
            timer: 3000,
          });
          getUsers();
        } else {
          let message = '<spam>';
          data.userCreated.forEach((item, index) => {
            message = `${message}<b>${index + 1}.</b> ${item.message}<br>`
          })
          message = message + '</spam>';
          Swal.fire({
            icon: "error",
            titleText: "Error con los siguiente datos.",
            title: "Error con los siguiente datos.",
            html: message,
            confirmButtonColor: "#d3c221",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          text: `Ocurrio un problema al crear los usuarios`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }).catch((error) => {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: `Ocurrio un problema al crear los usuarios`,
        showConfirmButton: false,
        timer: 3000,
      });
    });
  }

  const download = () => {
    saveAs(formato, "Formato de Registro de Usuarios.xlsx");
  }

  const deleteUser = {
    align: "Left",
    click: deleteUsersList,
    disabled: !permission.includes(4),
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Eliminar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const addUser = {
    align: "Left",
    click: registerUser,
    disabled: !permission.includes(2),
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Registrar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const bulkLoad = {
    align: "Left",
    click: upFile,
    disabled: !permission.includes(2),
    overflow: "None",
    prefixIcon: "e-upload",
    showTextOn: "Both",
    text: "Registro masivo",
    tooltipText: "Registro masivo",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const downloadFormatCreateUser = {
    align: "Left",
    click: download,
    overflow: "None",
    prefixIcon: "e-download",
    showTextOn: "Both",
    text: "Formato de registro",
    tooltipText: "Formato de registro ",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const EditUser = {
    align: "Left",
    click: editUsers,
    disabled: !permission.includes(3),
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Editar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    disabled: !permission.includes(5),
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "Exportar",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const toolbarOptions = ["Search", addUser, EditUser, deleteUser, bulkLoad, downloadFormatCreateUser, excelExport];

  const searchOptions = {
    fields: [
      "nombres",
      "grupoUsuarios.nombre",
      "telefono",
      "email",
      "documento",
      "apellidos",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getUsers = async () => {
    setLoading(true)
    await axios.post(
      "/user/getUsers",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false)
      if (groupId === 1) {
        setUsers(data?.users);
      } else {
        const usersFiltered = data?.users.filter(
          (item) => item.id_grupos_usuarios !== 1
        );
        setUsers(usersFiltered);
      }
      setEditId(0);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los usuarios.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getUsers();
      setLoading(false);
    } else {
      setLoading(false)
      history("/")
    }
  }, []);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelectedUsers([...selectedUsers, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter(
          (item) => item.id !== data.id
        );
        setSelectedUsers(selectedUsersFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selectedUsers.includes(item.id));
        setSelectedUsers([...selectedUsers, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter((item) =>
          data.includes(item.id)
        );
        setSelectedUsers(selectedUsersFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <DeleteUser
        show={showModalDelete}
        setShow={setShowModalDelete}
        selectedUsers={selectedUsers}
        userId={userId}
        token={token}
        setSelectedUsers={setSelectedUsers}
        getUsers={getUsers}
        setLoading={setLoading}
      />
      <ModalInfoUser
        show={showModalRegisterUser}
        setShow={setModalResiterUser}
        groupId={groupId}
        token={token}
        userId={userId}
        getUsers={getUsers}
        setLoading={setLoading}
      />
      <ModalInfoUser
        show={showModalEditUser}
        setShow={setShowModalEditUser}
        groupId={groupId}
        token={token}
        userId={userId}
        getUsers={getUsers}
        editMode
        editId={editId}
        setLoading={setLoading}
      />
      <Header category="Usuarios" title="Usuarios" />
      <GridComponent
        dataSource={users}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {userGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 2)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(Users);
