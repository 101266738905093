import React from "react";
import {
  Button,
  Modal,
  FloatingLabel,
  Form,
  Col,
  Row,
  Image,
} from "react-bootstrap";
import { AiOutlineClose, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import axios from "../../api";
import { weekDays } from '../../data/dummy'

const ModalInfoLudic = ({
  show,
  setShow,
  token,
  editMode,
  editId,
  getLudic,
  setLoading,
  spots,
}) => {
  const formatHour = (Hour) => {
    let hour = Hour.getHours();
    let minutes = Hour.getMinutes();

    if (hour < 10) hour = "0" + hour;

    if (minutes < 10) minutes = "0" + minutes;

    return [hour, minutes].join(":");
  };
  
  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [nombre, setNombre] = React.useState("");
  const [maximumHours, setMaximumHours] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  const [photo, setPhoto] = React.useState({
    ruta: "",
    file: "",
  });
  const [dates, setDates] = React.useState([]);
  const [date, setDate] = React.useState(formatDate(new Date()));
  const [hourStart, setHourStart] = React.useState(formatHour(new Date()));
  const [hourEnd, setHourEnd] = React.useState(formatHour(new Date()));
  const [validated, setValidated] = React.useState(false);
  const [originServer, setOriginServer] = React.useState(false);
  const [spotSelected, setSpotSelected] = React.useState(0);
  const [disabledWeekdays, setDisabledWeekdays] = React.useState([]);

  const clearFields = () => {
    setNombre("");
    setDescripcion("");
    setPhoto({
      ruta: "",
      file: "",
    });
    setDates([]);
    setHourStart(formatHour(new Date()));
    setHourEnd(formatHour(new Date()));
    setDate(new Date());
    setDisabledWeekdays([]);
    setMaximumHours("");
  };

  const handleClose = () => {
    if (!editMode) {
      clearFields();
    }
    getLudic();
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (editMode) {
        setValidated(false);
        editLudic();
      } else {
        addLudic();
      }
    }
  };

  const editLudic = async () => {
    handleClose();
    setLoading(true);
    if (photo.file.type) {
      let formFiles = new FormData();
      formFiles.append("file", photo.file);
      await axios
        .post(`/file/${"archivos_recursos_ludicos"}/${editId}`, formFiles, {
          headers: { "access-token": token },
        })
        .then((res) => {
          setPhoto({
            file: res.data.ruta,
            ruta: res.data.ruta,
          });
          axios
            .put(
              `/ludicResources/${editId}`,
              {
                nombre,
                url_imagen: res.data.ruta,
                descripcion,
                dias_inactivos: dates,
                id_espacios: spotSelected,
                inactiveDays: disabledWeekdays,
                horas_maxima: maximumHours,
              },
              {
                headers: { "access-token": token },
              }
            )
            .then((res) => {
              setLoading(false);
              if (res.data.updated) {
                clearFields();
                getLudic();
                Swal.fire({
                  icon: "success",
                  text: "Editado exitosamente.",
                  showConfirmButton: false,
                  timer: 3000,
                });
              } else {
                setShow(true);
                Swal.fire({
                  icon: "error",
                  text: res.data.updated.message,
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              setShow(true);
              console.error(error);
              Swal.fire({
                icon: "error",
                text: "No se ha podido editar.",
                showConfirmButton: false,
                timer: 3000,
              });
            });
        });
    } else {
      axios
        .put(
          `/ludicResources/${editId}`,
          {
            nombre,
            url_imagen: photo.ruta,
            descripcion,
            dias_inactivos: dates,
            id_espacios: spotSelected,
            inactiveDays: disabledWeekdays,
            horas_maxima: maximumHours,
          },
          {
            headers: { "access-token": token },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res.data.updated) {
            clearFields();
            getLudic();
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.updated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setShow(true);
          console.error(error);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }
  };

  const addLudic = () => {
    handleClose();
    setLoading(true);
    axios
      .post(
        `/ludicResources/`,
        {
          nombre,
          descripcion,
          id_espacios: spotSelected,
          url_imagen: "",
          inactiveDays: disabledWeekdays,
          horas_maxima: maximumHours,
        },
        {
          headers: { "access-token": token },
        }
      )
      .then(async (res) => {
        setLoading(false);
        if (res.data.created) {
          const formFiles = new FormData();
          formFiles.append("file", photo.file);
          let id = res.data.created;
          let ruta = await axios
            .post(`/file/${"archivos_recursos_ludicos"}/${id}`, formFiles, {
              headers: { "access-token": token },
            })
            .then((res) => {
              if (res.data.success) return res.data.ruta;
            });
          axios
            .put(
              `/ludicResources/${id}`,
              {
                url_imagen: ruta,
                dias_inactivos: dates,
                inactiveDays: disabledWeekdays,
              },
              {
                headers: { "access-token": token },
              }
            )
            .then(async (res) => {
              setLoading(false);
              if (res.data.updated) {
                getLudic();
                Swal.fire({
                  icon: "success",
                  text: "Recurso lúdico creado correctamente",
                  showConfirmButton: false,
                  timer: 3000,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Se creó el recurso lúdico, mas no fue posible anexarle la imagen subida",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.created.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const getLudicEdit = async () => {
    try {
      const { data } = await axios.get(`/ludicResources/${editId}`, {
        headers: { "access-token": token },
      });
      setOriginServer(true);
      setPhoto({
        ruta: data.ludicResource.url_imagen,
        file: data.ludicResource.url_imagen,
      });
      setMaximumHours(data.ludicResource.horas_maxima);
      setDescripcion(data.ludicResource.descripcion);
      setNombre(data.ludicResource.nombre);
      setSpotSelected(data.ludicResource.id_espacios);
      setDisabledWeekdays(
        data.ludicResource?.diasInactivoSemana?.map((e) => {
          return {
            value: e.dia,
            id: e.id,
          };
        })
      );
      setDates(
        data.ludicResource?.diasInactivo
          ?.sort((a, b) => {
            return new Date(b.fecha) - new Date(a.fecha);
          })
          .map((e) => ({
            id: e.id,
            fecha: formatDate(new Date(`${e.fecha} `)),
            hora_final: new Date(e.hora_final),
            hora_inicial: new Date(e.hora_inicial),
          })) || []
      );
      const fileInput = document.getElementById("photo");
      const list = new DataTransfer();
      list.items.add(
        new File(
          ["content"],
          `${axios.defaults.baseURL}/${data.ludicResource.url_imagen}`
        )
      );
      fileInput.files = list.files;
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del recurso",
        showConfirmButton: false,
        timer: 3000,
      });
      handleClose();
    }
  };

  React.useEffect(() => {
    if (editId !== 0 && editId !== undefined) {
      getLudicEdit();
    }
  }, [editId]);

  const addDate = () => {
    const startHour = new Date(`${date} ${hourStart}`);
    const EndHour = new Date(`${date} ${hourEnd}`);
    if (startHour >= EndHour) {
      Swal.fire({
        icon: "error",
        text: "La hora de inicio debe ser menor a la hora final",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    const newDate = {
      fecha: date,
      hora_inicial: startHour,
      hora_final: EndHour,
    };

    setDates([...dates, newDate]);
    setHourStart(formatHour(new Date()));
    setHourEnd(formatHour(new Date()));
    setDate(formatDate(new Date()));
  };

  const handleUpfiles = async (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener(
      "load",
      function () {
        setPhoto({ ruta: reader.result, file: e.target.files[0] });
        setOriginServer(false);
      },
      false
    );
  };

  const deleteDate = (dateSelected) => {
    const newDates = dates.filter(
      (date) =>
        !(
          date.fecha === dateSelected.fecha &&
          date.hora_final === dateSelected.hora_final &&
          date.hora_inicial === dateSelected.hora_inicial
        )
    );
    setDates(newDates);
  };

  return (
    <Modal show={show} size="xl">
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Editar Recurso" : "Crear Recurso"}
        </Modal.Title>
        <button type="button" className="btn-close" onClick={handleClose}>
          <AiOutlineClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Col>
              <Row className="mb-2">
                <Form.Group as={Col} md="12">
                  <FloatingLabel label="Nombre" className="mb" md="6">
                    <Form.Control
                      md="6"
                      type="text"
                      value={nombre}
                      onChange={(e) => {
                        setNombre(e.target.value);
                      }}
                      placeholder="Nombre"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      El nombre es obligatorio.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12">
                  <FloatingLabel label="Espacio" className="mb" md="6">
                    <Form.Select
                      onChange={(e) => {
                        setSpotSelected(e.target.value);
                      }}
                      value={spotSelected}
                      placeholder="Espacio"
                      required
                    >
                      <option defaultValue></option>
                      {spots
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((e) => (
                          <option key={e.id} value={e.id}>
                            {e.nombre}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      El espacio es obligatorio.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12">
                  <FloatingLabel label="Horas Maxima de Reserva" className="mb" md="6">
                    <Form.Control
                      md="6"
                      type="text"
                      value={maximumHours}
                      onChange={(e) => {
                        setMaximumHours(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        soloNumeros(e);
                      }}
                      maxLength="3"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Las Horas Maxima es obligatoria.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} md="12">
                  <FloatingLabel label="Descripción" className="mb" md="6">
                    <Form.Control
                      md="6"
                      as="textarea"
                      value={descripcion}
                      onChange={(e) => {
                        setDescripcion(e.target.value);
                      }}
                      placeholder="Descripción"
                      style={{ height: "100px" }}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </Col>
            <Col className="py-3 text-center">
              <Form.Group as={Col} md="6" style={{ width: "100%" }}>
                <Modal.Title className="mb-2" style={{ marginTop: "-4%" }}>
                  Imagen del Recurso
                </Modal.Title>
                <label htmlFor="photo">
                  {originServer ? (
                    <Image
                      roundedCircle
                      src={
                        photo.ruta.length === 0
                          ? `${axios.defaults.baseURL}/archivos_recursos_ludicos/NA==LudicDefault.png`
                          : `${axios.defaults.baseURL}${photo.ruta}`
                      }
                      width="200"
                      height="200"
                      border="0"
                    />
                  ) : (
                    <Image
                      roundedCircle
                      src={
                        photo.ruta.length === 0
                          ? `${axios.defaults.baseURL}/archivos_recursos_ludicos/NA==LudicDefault.png`
                          : photo.ruta
                      }
                      width="200"
                      height="200"
                      border="0"
                    />
                  )}
                  <Form.Control
                    id="photo"
                    type="file"
                    hidden={true}
                    files={[photo.file]}
                    onChange={(e) => {
                      handleUpfiles(e);
                    }}
                    accept=".jpg,.jpeg,.png"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La foto es obligatorio.
                  </Form.Control.Feedback>
                </label>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="border mx-3">
              <Row>
                <legend> Días y horas no disponible</legend>
                <Row>
                  <Col>
                    <Row className="mb-2">
                      <Form.Group as={Col} md="12">
                        <FloatingLabel label="Hora Inicial" className="mb" md="6">
                          <Form.Control
                            md="6"
                            type="time"
                            value={hourStart}
                            onChange={(e) => {
                              setHourStart(e.target.value);
                            }}
                            placeholder="Hora Inicial"
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <Form.Group as={Col} md="12">
                        <FloatingLabel label="Hora Final" className="mb" md="6">
                          <Form.Control
                            md="6"
                            type="time"
                            value={hourEnd}
                            onChange={(e) => {
                              setHourEnd(e.target.value);
                            }}
                            placeholder="Hora Final"
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col} md="12">
                    <FloatingLabel
                      label="Fecha no disponible"
                      className="mb"
                      md="6"
                    >
                      <Form.Control
                        md="6"
                        type="date"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        placeholder="Fecha no disponible"
                        min={formatDate(new Date())}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row>
                <Row className="mb-2 d-flex justify-content-end me-1">
                  <Button
                    className="align-self-center d-flex justify-content-center rounded-full col-1"
                    variant="outline-primary"
                    onClick={addDate}
                  >
                    <AiOutlinePlus className="align-self-center" />
                  </Button>
                </Row>
              </Row>
              <Row className="border-top my-2">
                <legend className="mt-2">Inhabilitar días de la semana</legend>
                {weekDays.map((e, row) => {
                  return (
                    <Form.Check
                      className="mx-3 "
                      key={`weekday${row}`}
                      checked={
                        disabledWeekdays.filter((e2) => e2.value === e.value)
                          .length > 0
                      }
                      onChange={function () {
                        let disabled = disabledWeekdays;
                        let index = disabled.findIndex((e2) => {
                          return e.value === e2.value;
                        });
                        if (index === -1) {
                          disabled.push({
                            value: e.value,
                          });
                        } else {
                          disabled = disabled.filter((_, idx) => idx !== index);
                        }
                        setDisabledWeekdays([...disabled]);
                      }}
                      label={`${e.name}`}
                    />
                  );
                })}
              </Row>
            </Col>
            <Col className="border me-3">
              <Table striped>
                <thead>
                  <tr>
                    <th className="col-1">Fecha</th>
                    <th className="col-1">Hora inicial</th>
                    <th className="col-1">Hora Final</th>
                    <th className="col-1"></th>
                  </tr>
                </thead>
                <tbody>
                  {dates
                    .sort((a, b) => {
                      return new Date(b.fecha) - new Date(a.fecha);
                    })
                    .map((e, index) => (
                      <tr key={index}>
                        <td className="col-1">{e.fecha}</td>
                        <td className="col-1">{formatHour(e.hora_inicial)}</td>
                        <td className="col-1">{formatHour(e.hora_final)}</td>
                        <td className="col-1">
                          <Button
                            className="align-self-center d-flex justify-content-center rounded-full"
                            variant="outline-danger"
                            onClick={() => {
                              deleteDate(e);
                            }}
                          >
                            <AiOutlineDelete className="align-self-center" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded-full"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded-full"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded-full"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoLudic;
