import React from "react";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  Month,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import { loadCldr } from "@syncfusion/ej2-base";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as gregorian from "cldr-data/main/es-CO/ca-gregorian.json";
import * as numbers from "cldr-data/main/es-CO/numbers.json";
import * as timeZoneNames from "cldr-data/main/es-CO/timeZoneNames.json";
import { GiTabletopPlayers } from "react-icons/gi";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "../../api";
import { Header } from "../../components";
import ModalAppointmantLudicInfo from "../../components/Modals/ModalAppointmantLudicInfo";
import "../styles/schedule.css";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FiCalendar } from "react-icons/fi";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

const LudicAppointmentManage = (props) => {
  const {
    groupId,
    userId,
    token,
    permissionBooking,
    currentLudic,
    currentSpot,
  } = props;
  const [disabledWeekdays, setDisabledWeekdays] = React.useState([]);
  const [scheduleObj, setScheduleObj] = React.useState({});
  const [bookings, setBookings] = React.useState([]);
  const [showModalRegisterAppointmant, setShowModalRegisterAppointmant] =
    React.useState(false);
  const [showModalEditAppointmant, setShowModalEditAppointmant] =
    React.useState(false);
  const [startHourSelected, setStartHourSelected] = React.useState(new Date());
  const [ludicBookingsSelected, setLudicBookingsSelected] = React.useState({});

  const getBookings = async () => {
    try {
      const id = currentLudic.id;
      const { data } = await axios.post(
        `/ludicResourcesBooking/getLudicResourcesBooking`,
        {
          id_recurso: id,
          estado: [1, 2, 3]
        },
        {
          headers: { "access-token": token },
        }
      );
      let obj = {};
      const info = [];
      const inactiveDays = [];
      data.ludicResourcesBookings.map((item) => {
        obj = {
          ...item,
          fecha_reserva: new Date(
            item.fecha_reserva.replace("T", " ").replace("Z", " ")
          ),
          bloques: [
            {
              fecha_inicio: new Date(
                item.bloques[0].fecha_inicio.replace("T", " ").replace("Z", " ")
              ),
              fecha_fin: new Date(
                item.bloques[0].fecha_fin.replace("T", " ").replace("Z", " ")
              ),
              id: item.bloques[0].id,
              id_reservas_espacios: item.bloques[0].id_reservas_espacios,
              estado: item.bloques[0].estado,
            },
          ],
        };
        info.push(obj);
      });
      data.ludicResourcesBookings[0]?.recurso?.diasInactivo.map((item) => {
        inactiveDays.push(item);
      });
      const events = await convertBookingsToAppointments(info);
      const disableEvents = await convertsDisableDatesToAppointments(
        inactiveDays
      );
      setBookings([...disableEvents, ...events]);
    } catch (error) {
      console.error(error);
    }
  };

  const convertBookingsToAppointments = async (bookings) => {
    if (!bookings) {
      return [];
    }
    let appointments = [];
    bookings.forEach((e) => {
      e.bloques.forEach((e2) => {
        let estado = "";
        switch (e.estado) {
          case -1:
            estado = "Cancelada";
            break;
          case 1:
            estado = "Por aprobar";
            break;
          case 2:
            estado = "Aprobada";
            break;
          case 3:
            estado = "Entregada";
            break;
          case 4:
            estado = "Devuelta";
            break;
        }
        appointments.push({
          Id: e.id,
          StartTime: e2.fecha_inicio,
          EndTime: e2.fecha_fin,
          Subject: `Reserva: ${e.nombre}, Estado: ${estado}`.trim(),
          title: `Reserva por: ${e.solicitante.nombres || ""} ${
            e.solicitante.apellidos || ""
          },
Recurso: ${e.recurso.nombre || ""}, 
Cantidad: ${e.cantidad_reservada || ""},
Estado: ${estado}`.trim(),
          CategoryColor: "#00bdae",
          Source: e.recurso.espacio.nombre,
          firstDate: e.bloques[0].fecha_inicio,
          estado: e.estado,
          nombre: e.nombre,
          id_usuario_recibe: e.id_usuario_recibe,
          id_usuario: e.id_usuarios,
          observaciones: e.observaciones,
          cantidadReservada: e.cantidad_reservada,
          recurso: e.recurso,
          IsBlock: estado === "Finalizado",
        });
      });
    });
    return appointments;
  };

  const convertsDisableDatesToAppointments = async (disableEvents = []) => {
    let appointments = [];
    disableEvents.forEach((element) => {
      if (element.estado === 1) {
        appointments.push({
          Id: element.id,
          StartTime: element.hora_inicial,
          EndTime: element.hora_final,
          Subject: "No disponible.",
          IsBlock: true,
        });
      }
    });
    return appointments;
  };

  React.useEffect(async () => {
    if (
      Object.keys(currentLudic).length !== 0 &&
      permissionBooking.includes(1)
    ) {
      let _ludic = currentLudic;
      setDisabledWeekdays([..._ludic?.diasInactivoSemana?.map((e) => e.dia)]);
      await getBookings();
    } else if (
      Object.keys(currentLudic).length === 0 &&
      permissionBooking.includes(1)
    ) {
      setDisabledWeekdays([]);
      setBookings([]);
      if (scheduleObj?.eventSettings) {
        scheduleObj.eventSettings.dataSource = [];
      }
    }
  }, [currentLudic]);

  const onPopupOpen = (args) => {
    if (args.type === "QuickInfo") {
      if (args.data.Id === undefined) {
        args.cancel = true;
        if (currentSpot === 0) {
          Swal.fire({
            text: "Debe selecionar un espacio y un recurso ludico antes de realizar la reserva.",
            icon: "warning",
            confirmButtonColor: "#d3c221",
            confirmButtonText: "Aceptar",
          });
          return;
        } else if (Object.keys(currentLudic).length === 0) {
          Swal.fire({
            text: "Debe selecionar un recurso antes de realizar la reserva.",
            icon: "warning",
            confirmButtonColor: "#d3c221",
            confirmButtonText: "Aceptar",
          });
          return;
        }
        if (permissionBooking.includes(2)) {
          if (args.data.StartTime < new Date()) {
            Swal.fire({
              icon: "error",
              text: "No se puede reservar en una fecha y hora menor a la actual",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setStartHourSelected(new Date(args.data.StartTime));
            setShowModalRegisterAppointmant(true);
          }
        }
      }
    } else {
      args.cancel = true;
    }
  };

  const edit = (props) => {
    setLudicBookingsSelected(props);
    setShowModalEditAppointmant(true);
  };

  const updateStatus = async (props, status, observacion) => {
    let updateForm = {
      form: {
        observacion: "",
        estado: status,
      },
      observations: props.observaciones,
      userId,
    };
    if (status === 3) {
      updateForm.form.id_usuario_recibe = userId;
    }
    axios
      .put(
        `/ludicResourcesBooking/${props.Id}`,
        { ...updateForm },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        if (res.data.updated) {
          let message = "";
          if (props.estado) {
            switch (status) {
              case -1:
                message = "La Reserva ha sido Cancelada.";
                break;
              case 2:
                message = "La Reserva ha sido Aprobada.";
                break;
              case 3:
                message = "La Reserva ha sido Recepcionada.";
                break;
              case 4:
                message = "La Reserva ha sido Finalizada.";
                break;
            }
          } else {
            message = "Editada exitosamente.";
          }

          Swal.fire({
            icon: "success",
            text: message,
            showConfirmButton: false,
            timer: 3000,
          });
          getBookings();
          scheduleObj.closeQuickInfoPopup();
          setTimeout(() => {
            if (observacion) {
              Swal.fire({
                text: "¿quiere agregar alguna observacion de alguno de los rescursos de la reserva?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d3c221",
                cancelButtonColor: "#282828",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
              }).then((result2) => {
                if (result2.value) {
                  const filter = bookings.filter(
                    (booking) => booking.Id === parseInt(res.data.updated)
                  );
                  setLudicBookingsSelected(filter[0]);
                  setShowModalEditAppointmant(result2.value);
                }
              });
            }
          }, 3000);
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.updated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;

    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const header = (props) => {
    return (
      <div className="e-event-header e-popup-header pb-2">
        <div className="e-header-icon-wrapper">
          <button
            id="btnClose"
            className="e-close e-close-icon e-icons e-btn e-round e-small mx-1"
            title="Cerrar"
            onClick={() => {
              scheduleObj.closeQuickInfoPopup();
            }}
          />
        </div>
        <div className="text-light">
          <div
            className="e-subject font-weight-bold h5 mt-2"
            title={props.title}
          >
            {props.title}
          </div>
        </div>
      </div>
    );
  };

  const content = (props) => {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const date = new Date(props.firstDate);
    return (
      <div>
        {props.elementType !== "cell" ? (
          <div className="e-event-content e-template">
            <div className="e-date-time">
              <div className="e-date-time-icon e-icons" />
              <div className="e-date-time-wrapper e-text-ellipsis">
                <div className="e-date-time-details e-text-ellipsis">
                  {`${
                    meses[date.getMonth()]
                  } ${date.getDate()}, ${date.getUTCFullYear()} (${props.StartTime.toLocaleTimeString()} - ${props.EndTime.toLocaleTimeString()})`}
                </div>
              </div>
            </div>
            <div className="e-location">
              <div className="e-location-icon e-icons" />
              <div className="e-location-details e-text-ellipsis">
                {props.Source}
              </div>
            </div>
            {props.isClass && (
              <div className="my-3 flex">
                <GiTabletopPlayers color="#757575" size="20" />
                <label
                  style={{
                    fontSize: 13,
                    marginLeft: "3%",
                  }}
                >
                  Es una clase.
                </label>
              </div>
            )}
            {props.observaciones.length > 0 &&
              props.observaciones.map((item) => (
                <div className="e-description" key={item.id}>
                  <div className="e-description-icon e-icons" />
                  <div className="e-description-details e-text-ellipsis">
                    {`${formatDate(item.fecha)} -> - ${item.detalle}`}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalAppointmantLudicInfo
        show={showModalRegisterAppointmant}
        setShow={setShowModalRegisterAppointmant}
        startHourSelected={startHourSelected}
        token={token}
        userId={userId}
        currentLudic={currentLudic}
        getBookings={getBookings}
        groupId={groupId}
      />
      <ModalAppointmantLudicInfo
        show={showModalEditAppointmant}
        setShow={setShowModalEditAppointmant}
        ludicBookingsSelected={ludicBookingsSelected}
        token={token}
        userId={userId}
        currentLudic={currentLudic}
        getBookings={getBookings}
        upDateObservations={updateStatus}
        editMode
        groupId={groupId}
      />
      <Header
        category="RECURSOS LÚDICOS"
        title="Gestión De Reservas De Recursos Lúdicos"
        subtite={currentLudic.nombre}
      />
      <ScheduleComponent
        height="800px"
        startHour="08:00"
        endHour="18:00"
        workDays={[0, 1, 2, 3, 4, 5, 6].filter(
          (e) => !disabledWeekdays?.includes(e)
        )}
        ref={(schedule) => setScheduleObj(schedule)}
        eventSettings={{ dataSource: bookings, isBlock: true }}
        dragStart={(arg) => {
          arg.navigation.enable = true;
        }}
        showWeekend={false}
        timeScale={{ enable: true, interval: 60, slotCount: 6 }}
        popupOpen={onPopupOpen}
        quickInfoTemplates={{
          header: header,
          content: content,
        }}
        EditEventInfo={edit}
        locale="es-CO"
        minDate={new Date(new Date().getTime() - 24 * 60 * 60 * 1000)}>
        <ViewsDirective>
          {["Day", "Week", "Month"].map((item) => (
            <ViewDirective key={item} option={item} />
          ))}
        </ViewsDirective>
        <Inject services={[Day, Week, Month]} />
      </ScheduleComponent>
      <div className="fixed right-4 bottom-20" style={{ zIndex: "1000" }}>
        <TooltipComponent content="Agendar" position="Top">
          <button
            type="button"
            onClick={() => {
              const args ={
                cancel:true,
                type: "QuickInfo",
                data:{
                  StartTime : new Date(new Date().getTime() + 30 * 60000)
                }
              }
              onPopupOpen(args);
            }}
            style={{ background: '#d3c211', borderRadius: "50%" }}
            className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray">
            <FiCalendar />
          </button>
        </TooltipComponent>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permissionBooking: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 14)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(LudicAppointmentManage);
