import React, { useEffect, useState } from 'react'
import { Button, Modal, ListGroup } from 'react-bootstrap';


const Participant = ({ show, setShow, selected }) => {
    const handleClose = () => setShow(false);
    const [participants, setParticipants] = useState([]);
    useEffect(() => {
        if (show === true && selected.reserva_participante.length>0){
            const participantes = []
            for (let index = 0; index < selected.reserva_participante.length; index++) {
                participantes.push(selected.reserva_participante[index].usuario);
            }
            setParticipants(participantes);
        }
    }, [selected]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Lista de Asistencias</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {participants?.map((item) =>
                    (
                        <ListGroup.Item key={item.id}>
                            {`${item.documento} - ${item.nombres} ${item.apellidos}`}
                        </ListGroup.Item>
                    ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Participant;