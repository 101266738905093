import React, { useEffect, useState } from 'react'
import { Button, Modal, ListGroup } from 'react-bootstrap';


const Stock = ({ show, setShow, selected }) => {
    const handleClose = () => setShow(false);
    const [stock, setStock] = useState([]);
    useEffect(() => {
        setStock(selected?.existencias_reserva);
    }, [selected]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Recurso Entregados</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {stock?.map((item) =>
                    (
                        <ListGroup.Item key={item.id}>
                            {item?.existencia?.codigo_barras}
                        </ListGroup.Item>
                    ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded-full" variant="outline-dark" onClick={handleClose}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Stock;