import React, { useEffect, useState } from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../api";

const ModalInfoUser = ({
  show,
  setShow,
  token,
  // editMode,
  editId,
  groupId,
  userId,
  viewMode,
  get,
  setLoading,
}) => {
  const [action, setAction] = useState([]);
  const [modules, setModules] = useState([]);
  const [userGroup, setUserGroups] = useState([]);
  const [checked, setChecked] = useState([]);
  const [idModule, setIdModule] = useState("");
  const [idUserGroups, setIdUserGroups] = useState("");
  const [validated, setValidated] = useState(false);

  const clearFields = () => {
    setChecked([]);
    setAction([]);
    setIdModule("");
    setIdUserGroups("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    clearFields();
  };

  const handleToggleChecked = (value) => {
    const result = [...action].map((item) => {
      if (item.id === value.id) {
        item.activation = !item.activation;
      }
      return item;
    })
    setAction(result);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (action.filter(item => item.activation).length > 0) {
        // if (editMode) {
        //   editUser();
        // } else {
        addUser();
        // }
      } else {
        Swal.fire({
          icon: "error",
          text: "Seleccione al menos una acción",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  // const editUser = () => {
  //   const id_modulos = idModule;
  //   const id_acciones = checked;
  //   const form = {
  //     id_modulos,
  //     id_acciones,
  //   };
  //   handleClose();
  //   setLoading(true);
  //   axios
  //     .put(`/moduleAction/${editId}`, form, {
  //       headers: { "access-token": token },
  //     })
  //     .then((res) => {
  //       setTimeout(async () => {
  //         setLoading(false);
  //         if (res.data.updated) {
  //           clearFields();
  //           get();
  //           Swal.fire({
  //             icon: "success",
  //             text: "Editado exitosamente.",
  //             showConfirmButton: false,
  //             timer: 3000,
  //           });
  //         } else {
  //           setShow(true);
  //           Swal.fire({
  //             icon: "error",
  //             text: res.data.updated.message,
  //             showConfirmButton: false,
  //             timer: 3000,
  //           });
  //         }
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setTimeout(() => {
  //         setLoading(false);
  //         setShow(true);
  //         Swal.fire({
  //           icon: "error",
  //           text: "No se ha podido editar.",
  //           showConfirmButton: false,
  //           timer: 3000,
  //         });
  //       }, 2000);
  //     });
  // };

  const addUser = () => {
    const id_modulos = idModule;
    const id_grupos_usuarios = idUserGroups;
    const id_acciones = action.filter(item => item.activation).map(item => (item.id));
    const form = {
      id_modulos,
      id_grupos_usuarios,
      id_acciones,
    };
    handleClose();
    setLoading(true);
    axios
      .post(
        `/permission/`,
        { ...form, userId },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          if (res.data.created) {
            clearFields();
            get();
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userCreated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
        console.error(error);
      });
  };

  const getActions = async (id) => {
    try {
      const { data } = await axios.post(
        `/moduleAction/getActionsModules`,
        { id_modulos: id },
        {
          headers: { "access-token": token },
        }
      );
      const acciones = data.actions.map((item) => ({
        ...item,
        activation: false,
      }));
      setAction(acciones);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar las acciones.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getModules = async () => {
    try {
      const { data } = await axios.post(
        `/module/getModules`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      setModules(data.modules);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los modulos.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getUserGroups = async () => {
    try {
      const { data } = await axios.post(
        `/userGroup/getGroups`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      setUserGroups(data.groups);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los modulos.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getPermission = async (module = null) => {
    try {
      // si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
      const { data } = await axios.post(
        `/permission/getPermissions`,
        {
          id_grupos_usuarios: editId?.id_grupos_usuarios,
          id_modulos: module ? module : idModule,
        },
        {
          headers: { "access-token": token },
        }
      );
      const acciones = data.permissions.map(
        (item) => item?.modulosAcciones?.acciones?.id
      );
      setChecked(acciones);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los modulos",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    getModules();
    getUserGroups();
    if (editId !== 0 && editId !== undefined) {
      setIdModule(editId.modulosAcciones.id_modulos);
      setIdUserGroups(editId.id_grupos_usuarios);
      getPermission(editId.modulosAcciones.id_modulos);
    }
  }, [editId]);

  useEffect(() => {
    getActions(idModule);
  }, [idModule, idUserGroups]);

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          {viewMode ? "Ver" : "Crear"} Permisos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} >
              <FloatingLabel
                controlId="grupUser"
                label="Grupo Ususario"
                className="mb"
              >
                <Form.Select
                  onChange={(e) => setIdUserGroups(e.target.value)}
                  value={idUserGroups}
                  placeholder="Grupo de Usuario"
                  required
                  disabled={viewMode}
                >
                  <option defaultValue></option>
                  {userGroup
                    .sort((a, b) => (a.nombre < b.nombre ? -1 : 1))
                    .map((data) => {
                      return (
                        <option key={`UserGroup-${data.id}`} value={data.id}>
                          {data.nombre}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El modulo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} >
              <FloatingLabel
                controlId="grupUser"
                label="Modulos"
                className="mb"
              >
                <Form.Select
                  onChange={(e) => setIdModule(e.target.value)}
                  value={idModule}
                  placeholder="Grupo de Usuario"
                  required
                  disabled={viewMode}
                >
                  <option defaultValue></option>
                  {modules
                    .sort((a, b) => (a.nombre < b.nombre ? -1 : 1))
                    .map((data) => {
                      return (
                        <option key={`module-${data.id}`} value={data.id}>
                          {data.nombre}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El modulo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row>
            <Modal.Title>Acciones</Modal.Title>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="12" >
              {viewMode ? (
                action?.map((data) => (
                  <Form.Check
                    type="checkbox"
                    key={data?.id}
                    disabled={viewMode}
                    checked={checked.some((e) => e === data?.id_acciones)}
                    label={data?.acciones?.nombre}
                  />
                ))
              ) :
                (
                  action?.map((data) => (
                    <Form.Check
                      type="checkbox"
                      key={data?.id}
                      disabled={viewMode}
                      checked={data.activation}
                      label={data?.acciones?.nombre}
                      onChange={() => { handleToggleChecked(data) }}
                    />
                  ))
                )
              }
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded-full"
              variant="outline-dark"
              onClick={handleClose}
            >
              {viewMode ? "Salir" : "Cancelar"}
            </Button>
            {
              viewMode ? <></> : (
                <Button
                  className="btn rounded-full"
                  variant="outline-success"
                  type="submit"
                >
                  Registrar
                </Button>
              )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoUser;
