import { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { RequestSpotGrid } from "../../data/dummy";
import { Header } from "../../components";
import Swal from "sweetalert2";
import { Backdrop } from "../../components";
import { ModalAppointmantSpotsInfo, Participant } from "../../components/Modals";

const request = (props) => {
  const { token, userId, groupId, permission } = props;
  const [request, setRequest] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalObservations, setShowModalObservations] = useState(false);
  const [showParticipant, setShowParticipant] = useState(false);
  const [spotsBookingsSelected, setSpotsBookingsSelected] = useState({});

  const history = useNavigate();

  // const exportExcel = async () => {
  //   const arrayExcel = await request?.map((item) => {
  //     const xlsx = {
  //       Espacio: item.item.nombre,
  //       Detalle: item.item.espacio?.nombre,
  //       "Horario reserva": item.formatfecha,
  //       "Personas a ingresar": item.item.reserva_participante.length,
  //       Estado: item.item.estado,
  //     };
  //     return xlsx;
  //   });
  //   await exportToExcel(arrayExcel, "Solicitudes espacios");
  // };

  // const excelExport = {
  //   align: "Left",
  //   click: exportExcel,
  //   disabled: !permission.includes(5),
  //   id: "grid_245085904_0_excelexport",
  //   overflow: "None",
  //   prefixIcon: "e-excelexport",
  //   showTextOn: "Both",
  //   text: "Exportar",
  //   tooltipText: "ExcelExport",
  //   type: "Button",
  //   visible: true,
  //   width: "auto",
  // };

  const toolbarOptions = ["Search"];

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const formatDate = (inicio, fin) => {
    const fInicio = new Date(inicio.replace("T", " ").replace("Z", " "));
    const fFin = new Date(fin.replace("T", " ").replace("Z", " "));

    return `
    ${fInicio.getHours() < 10 ? "0" : ""}${fInicio.getHours()}:${
      fInicio.getMinutes() < 10 ? "0" : ""
    }${fInicio.getMinutes()}:${
      fInicio.getSeconds() < 10 ? "0" : ""
    }${fInicio.getSeconds()} - ${
      fFin.getHours() < 10 ? "0" : ""
    }${fFin.getHours()}:${
      fFin.getMinutes() < 10 ? "0" : ""
    }${fFin.getMinutes()}:${
      fFin.getSeconds() < 10 ? "0" : ""
    }${fFin.getSeconds()}
    ${fInicio.getDate() < 10 ? "0" : ""}${fInicio.getDate()}/${
      fInicio.getMonth() + 1 < 10 ? "0" : ""
    }${fInicio.getMonth() + 1}/${fInicio.getFullYear()}
    `;
  };
  
  const getRequest = async () => {
    setLoading(true);
    await axios
      .post(
        "/spotBooking/getSpotsBookings",
        { estado: [1, 2, 3] },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        let obj = {};
        const info = [];
        data?.spotBookings.forEach((e) => {
          e.bloques.forEach((e2) => {
            obj = {
              item:{
                ...e,
                StartTime: new Date(e2.fecha_inicio),
                EndTime: new Date(e2.fecha_fin),
                firstDate: e2.fecha_inicio,
              },
              formatfecha: formatDate(
                e.bloques[0].fecha_inicio,
                e.bloques[0].fecha_fin
              ),
              modal:
                e.estado === 1
                  ? approve
                  : e.estado === 2
                  ? reception
                  : finalize,
              observations: openObservations,
              cancel,
              openParticipant,
            };
            info.push(obj);
          });
        });
        setRequest(info);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar las solicitudes.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const approve = (props) => {
    Swal.fire({
      text: "¿Está seguro de aprobar esta reserva?",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      denyButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed || result.isDenied) {
        if (result.value) {
          updateStatus(props, 2);
        } else {
          updateStatus(props, -1);
        }
      }
    });
  };

  const reception = async (props) => {
    const result = await Swal.fire({
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      
      text: "¿Está seguro de recepcionar esta reserva?",
      /*input: "textarea",
      inputPlaceholder: "Observaciones",
      inputAttributes: {
        "aria-label": "Observaciones",
      },*/
    });
    if (result.value !== undefined) {
      if (result.value.length > 0) {
        const obj = {
          detalle: result.value,
          fecha: new Date().toISOString(),
          id_usuarios: userId,
        };
        props.observaciones.push(obj);
      }
      updateStatus(props, 3);
    }
  };

  const finalize = (props) => {
    Swal.fire({
      text: "¿Está seguro de Finalizar esta reserva?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        updateStatus(props, 4);
      }
    });
  };

  const cancel = (props) => {
    Swal.fire({
      text: "¿Está seguro de cancelar esta reserva?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        updateStatus(props, -1);
      }
    });
  };

  const openObservations = (props)=>{
    setSpotsBookingsSelected(props);
    setShowModalObservations(true);
  };

  const openParticipant = (props)=>{
    setSpotsBookingsSelected(props);
    setShowParticipant(true);
  };

  const updateStatus = async (props, status) => {
    let updateForm = {
      form: {
        observacion: "",
        estado: status,
      },
      observations: props.observaciones,
      userId,
    };
    if (status === 3) {
      updateForm.form.id_usuario_recibe = userId;
    }
    axios
      .put(
        `/spotBooking/${props.id}`,
        { ...updateForm },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        if (res.data.updated) {
          let message = "";
          if (props.estado) {
            switch (status) {
              case -1:
                message = "La Reserva ha sido Cancelada.";
                break;
              case 2:
                message = "La Reserva ha sido Aprobada.";
                break;
              case 3:
                message = "La Reserva ha sido Recepciondaa.";
                break;
              case 4:
                message = "La Reserva ha sido Finalizado.";
                break;
            }
          } else {
            message = "Editado exitosamente.";
          }
          Swal.fire({
            icon: "success",
            text: message,
            showConfirmButton: false,
            timer: 3000,
          });
          getRequest();
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.updated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getRequest();
      setLoading(false);
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  // const exportToExcel = (jsonData, fileName) => {
  //   const fileType =
  //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //   const fileExtension = ".xlsx";
  //   if (jsonData.length > 0) {
  //     const ws = XLSX.utils.json_to_sheet(jsonData);
  //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //     const data = new Blob([excelBuffer], { type: fileType });
  //     FileSaver.saveAs(data, fileName + fileExtension);
  //   }
  // };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelected([...selected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) => item.id !== data.id);
        setSelected(selectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selected.includes(item.id));
        setSelected([...selected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) =>
          data.includes(item.id)
        );
        setSelected(selectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalAppointmantSpotsInfo
        show={showModalObservations}
        setShow={setShowModalObservations}
        spotsBookingsSelected={spotsBookingsSelected}
        token={token}
        userId={userId}
        spotId={spotsBookingsSelected.espacio }
        getBookings={getRequest}
        upDateObservations={updateStatus}
        groupId={groupId}
        editMode
      />
      <Participant
        show={showParticipant}
        setShow={setShowParticipant}
        selected={spotsBookingsSelected}
      />

      <Header category="Espacio" title="Solicitudes" />
      <GridComponent
        dataSource={request}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {RequestSpotGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      <Backdrop open={loading} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userId: state.user?.id,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(request);
