import React, { useState, useEffect } from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../api";
import moment from "moment";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";

const ModalAppointmantSpotsInfo = ({
  show,
  setShow,
  editMode,
  startHourSelected,
  token,
  userId,
  spotId,
  spotsBookingsSelected,
  getBookings,
  upDateObservations,
  groupId,
  user = {}
}) => {
  const [validated, setValidated] = React.useState(false);
  const [startHour, setStartHour] = React.useState(new Date());
  const [endHour, setEndHour] = React.useState(new Date());
  const [day, setDay] = React.useState(new Date());
  const [date, setDate] = React.useState(new Date());
  const [repeat, setRepeat] = React.useState(false);
  const [repeatKind, setRepeatKind] = React.useState("");
  const [name, setName] = React.useState("");
  const [endDate, setEndDate] = React.useState(new Date());
  const [observations, setObservations] = React.useState([]);
  const [observation, setObservation] = React.useState("");
  const [idBooking, setIdBooking] = React.useState(0);
  const [isClass, setIsClass] = React.useState(false);
  const [currentAvailability, setCurrentAvailability] = useState(0);
  const [listUsers, setListUsers] = React.useState([]);
  const [capacity, setCapacity] = React.useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (!editMode) {
        if (capacity.length > currentAvailability) {
          Swal.fire({
            icon: "error",
            text: "Por favor, digite una capacidad menor o igual a la actual",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          addSpots();
        }
      } else {
        upDateBooking();
      }
    }
  };

  const upDateBooking = async () => {
    await upDateObservations({ id: idBooking, observaciones: observations, }, 3);
    // await getBookings();
    handleClose();
  };

  const getAvailability = async () => {
    let format = day;
    const { data } = await axios.post(
      "/spotBooking/getSpotsBookings",
      {
        fecha_inicio: moment(`${format} ${startHour}:00`).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        fecha_fin: moment(`${format} ${endHour}:00`).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        id_espacios: spotId.id,
      },
      {
        headers: {
          "access-token": token,
        },
      }
    );
    let capacidad = 0;
    data?.spotBookings?.forEach((e) => {
      capacidad += e.reserva_participante.length;
    });
    if (spotId.capacidad - capacidad === 0) {
      handleClose();
      Swal.fire({
        icon: "error",
        text: "No se puede realizar una reserva si la cantidad disponible es 0",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setCurrentAvailability(spotId.capacidad - capacidad);
  };

  const addSpots = () => {
    if (!isClass) {
      const startHour2 = new Date();

      startHour2.setHours(startHour.split(":")[0]);
      startHour2.setMinutes(startHour.split(":")[1]);
      startHour2.setSeconds("00");

      const limitEndHour2 = new Date(startHour2);
      const endHour2 = new Date(startHour2);

      limitEndHour2.setHours(startHour2.getHours() + spotId.horas_maxima);

      endHour2.setHours(endHour.split(":")[0]);
      endHour2.setMinutes(endHour.split(":")[1]);
      endHour2.setSeconds("00");

      if (endHour2 > limitEndHour2) {
        Swal.fire({
          position: "center",
          text: `Lo máximo que se puede reservar son ${spotId.horas_maxima} horas`,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    }
    Swal.fire({
      icon: "info",
      text: "¿Está seguro de querer realizar una reserva a las horas marcadas (Si eligió la opción repetir, esta no aplicará a los fines de semana)?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
    }).then((res) => {
      if (res.value) {
        if (repeat) {
          if (!repeatKind.trim()) {
            Swal.fire({
              icon: "error",
              text: "Debe seleccionar una frecuencia para poder realizar una reserva que se repita",
            });
          } else {
            const form = {
              startHour,
              endHour,
              day,
              date,
              repeat,
              repeatKind,
              isClass,
              name: name.trim(),
              capacity,
              endDate: formatDate(endDate),
            };
            axios
              .post(
                `/spotBooking/`,
                {
                  ...form,
                  id_usuarios: userId,
                  id_espacios: spotId.id,
                },
                {
                  headers: {
                    "access-token": token,
                  },
                }
              )
              .then((res) => {
                if (res.data.created.success) {
                  Swal.fire({
                    icon: "success",
                    text: "Reserva creada correctamente.",
                    showConfirmButton: false,
                    timer: 3000,
                  }).then(()=>{
                    Swal.fire({
                      icon: "warning",
                      text: "Todos los espacios deben ir en el orden establecido, los elementos en la misma posición; agradecemos conservar la política de orden y aseo.",
                      showConfirmButton: false,
                      timer: 3000,
                    })
                  });;
                  getBookings();
                  handleClose();
                } else {
                  Swal.fire({
                    icon: "error",
                    text: res.data.created.message,
                    showConfirmButton: false,
                    timer: 3000,
                  });
                }
              });
          }
        } else {
          const form = {
            startHour,
            endHour,
            day,
            date,
            repeat,
            repeatKind,
            name: name.trim(),
            isClass,
            capacity,
            endDate: formatDate(endDate),
          };
          axios
            .post(
              `/spotBooking/`,
              {
                ...form,
                id_usuarios: userId,
                id_espacios: spotId.id,
              },
              {
                headers: {
                  "access-token": token,
                },
              }
            )
            .then((res) => {
              if (res.data.created.success) {
                Swal.fire({
                  icon: "success",
                  text: "Reserva creada correctamente.",
                  showConfirmButton: false,
                  timer: 3000,
                }).then(()=>{
                  Swal.fire({
                    icon: "warning",
                    text: "Todos los espacios deben ir en el orden establecido, los elementos en la misma posición; agradecemos conservar la política de orden y aseo.",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                });;
                getBookings();
                handleClose();
              } else {
                Swal.fire({
                  icon: "error",
                  text: res.data.created.message,
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            });
        }
      }
    });
  };

  const clearFields = () => {
    setDay("");
    setDate("");
    setRepeat(false);
    setRepeatKind("");
    setCapacity([]);
    setName("");
    setEndDate("");
    setObservations([]);
    setObservation("");
    setIdBooking(0);
  };

  const handleClose = () => {
    if (!editMode) {
      clearFields();
    }
    setShow(false);
    setValidated(false);
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return [
      newDate.getFullYear(),
      padTo2Digits(newDate.getMonth() + 1),
      padTo2Digits(newDate.getDate()),
    ].join("-");
  };

  const startDateEnd = (date) => {
    const newDate = new Date(formatDate(date));
    const date2 = new Date(newDate.setDate(newDate.getDate() + 2));
    return date2;
  };

  useEffect(() => {
    if (startHourSelected) {
      const initHour = startHourSelected.toLocaleTimeString("en-GB", {
        minute: "numeric",
        hour: "numeric",
      });

      if (initHour < "08:00") {
        setStartHour("08:00");
        setEndHour("08:30");
      } else if (initHour > "21:30") {
        setStartHour("21:30");
        setEndHour("22:00");
      } else {
        setStartHour(initHour);
        setEndHour(
          new Date(startHourSelected.getTime() + 30 * 60000).toLocaleTimeString(
            "en-GB",
            {
              minute: "numeric",
              hour: "numeric",
            }
          )
        );
      }
      setDay(moment(startHourSelected).format().split("T")[0]);
      setDate(moment(startHourSelected).format().split("T")[0]);
      setEndDate(
        moment(startDateEnd(startHourSelected)).format().split("T")[0]
      );
      setRepeat(false);
      getUsers();
      setCapacity([user])
    }
  }, [startHourSelected]);

  useEffect(() => {
    if (show) {
      getAvailability();
    }
  }, [startHour, endHour, show]);

  useEffect(() => {
    if (
      spotsBookingsSelected !== undefined &&
      Object.keys(spotsBookingsSelected).length !== 0
    ) {
      setCurrentAvailability(spotId.capacidad);
      setStartHour(
        spotsBookingsSelected.StartTime.toLocaleTimeString("en-GB", {
          minute: "numeric",
          hour: "numeric",
        })
      );
      setEndHour(
        spotsBookingsSelected.EndTime.toLocaleTimeString("en-GB", {
          minute: "numeric",
          hour: "numeric",
        })
      );
      setDay(moment(spotsBookingsSelected.firstDate).format().split("T")[0]);

      setDate(moment(spotsBookingsSelected.firstDate).format().split("T")[0]);
      setObservations(spotsBookingsSelected.observaciones);
      setIdBooking(spotsBookingsSelected.id);
      let reserva_participante = [];
      spotsBookingsSelected.reserva_participante.forEach(element => {
        reserva_participante.push(element.usuario);
      });
      setCapacity(reserva_participante);
      setName(spotsBookingsSelected?.nombre || "");
    }
  }, [spotsBookingsSelected]);

  const formatObservation = () => {
    return observations
      .map((item) => `${formatDate(item.fecha)} -> ${item.detalle}`)
      .join("\n");
  };

  const addObservation = () => {
    const obj = {
      detalle: observation,
      fecha: new Date().toISOString(),
      id_usuarios: userId,
      id_reservas_espacios: idBooking,
    };
    setObservations([...observations, obj]);
    setObservation("");
  };

  //   function addDaysToDate(date, days){
  //     var res = new Date(date);
  //     res.setDate(res.getDate() + days);
  //     return res;
  // }

  const getUsers = async () => {
    // setLoading(true)
    await axios.post(
      "/user/getUsers",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      // setLoading(false)}

      const usersFiltered = data?.users.filter(
        (item) => item.id_grupos_usuarios !== 1
      );
      setListUsers([...usersFiltered, user]);

      // setEditId(0);
    }).catch((err) => {
      // setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los usuarios.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn btn-outline-primary dropdown-toggle"
    >
      {children}
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Buscar Usuario"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
            )}
          </ul>
        </div>
      );
    },
  );

  const filterListUser = () => {
    const listId = capacity.map((item) => item.id);
    return listUsers.filter((item) => !listId.includes(item.id));
  }


  const validateHours = () => {
    let status = false;
    let message = ''
    const ignoreValidate = [9, 1]
    if (!ignoreValidate.includes(groupId)) {
      if (endHour > "22:00" || startHour > "22:00") {
        status = true
        message = "La hora máxima es 10:00 p.m."
      }
      else if (endHour < "06:00" || startHour < "06:00") {
        status = true
        message = "La hora mínima es 08:00 a.m."
      } else {
        return { status, message }
      }
    }
    if (startHour >= endHour) {
      status = true,
        message = "La hora inicial debe ser menor a la final."
    }
    return { status, message }
  }

  return (
    <Modal show={show} size="xl">
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Agregar Comentario" : "Solicitar Reserva"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <FloatingLabel label="Fecha" className="mb">
                <Form.Control
                  type="date"
                  value={day}
                  onChange={(e) => {
                    setDay(e.target.value);
                  }}
                  placeholder="Fecha"
                  min={formatDate(new Date())}
                  disabled={editMode}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  La fecha de reserva es obligatoria.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Hora Inicial" className="mb">
                <Form.Control
                  type="time"
                  value={startHour}
                  onChange={(e) => {
                    setStartHour(e.target.value);
                  }}
                  placeholder="Hora Inicial"
                  disabled={editMode}
                  min="08:00"
                  max="22:00"
                  required
                  isInvalid={validateHours().status}
                />
                <Form.Control.Feedback type="invalid">
                  {validateHours().message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label="Hora Final" className="mb">
                <Form.Control
                  type="time"
                  value={endHour}
                  onChange={(e) => {
                    setEndHour(e.target.value);
                  }}
                  placeholder="Hora Final"
                  disabled={editMode}
                  min="08:00"
                  max="22:00"
                  required
                  isInvalid={validateHours().status}
                />
                <Form.Control.Feedback type="invalid">
                  {validateHours().message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <div className="col-3">
              <Form.Group>
                <FloatingLabel label="Descripción del evento" className="mb">
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Descripción del evento"
                    disabled={editMode}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La descripción es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </div>
            <Col>
              <Row>
                {!editMode && (
                  <Form.Group as={Col} className="d-flex align-items-center">
                    <Form.Check
                      label="Repetir"
                      value={repeat}
                      onChange={(e) => {
                        setRepeat(e.target.checked);
                      }}
                      placeholder="Repetir"
                    />
                  </Form.Group>
                )}
              </Row>
              {(
                groupId === 1 ||
                groupId === 9 ||
                groupId === 11 ||
                groupId === 12 ||
                groupId === 13 ||
                groupId === 14 ||
                groupId === 15 ||
                groupId === 16 ||
                groupId === 17 ||
                groupId === 18 ||
                groupId === 19 ||
                groupId === 20 ||
                groupId === 21
              ) && (
                  <Row>
                    <Form.Group as={Col} className="d-flex align-items-center">
                      <Form.Check
                        label="Sin restricción de tiempo"
                        value={isClass}
                        onChange={(e) => {
                          setIsClass(e.target.checked);
                        }}
                        placeholder="Sin restricción de tiempo"
                        disabled={editMode}
                      />
                    </Form.Group>
                  </Row>
                )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col className="border ml-3 mb-2" style={{ borderColor: '#CED4DA' }}>
              <Row className="border-bottom" style={{ borderColor: '#CED4DA' }}>
                <Col className="text-center fw-bold">
                  Participante
                </Col>
                <Col className=" text-center fw-bold">
                  {
                    `Aforo a reservar - disponible: ${currentAvailability - capacity.length}`
                  }
                </Col>
              </Row>
              {
                capacity.map((item) => (
                  <Row key={item.id} className=" text-center align-items-center border-top" style={{ borderColor: '#CED4DA' }}>
                    <Col >{`${item.documento} - ${item.nombres} ${item.apellidos}`}</Col>
                    <Col className="my-2 aling-center align-items-center d-flex justify-content-center">
                      <Button
                        className="align-self-center d-flex justify-content-center rounded-full"
                        variant="outline-danger"
                        onClick={() => { setCapacity(capacity.filter((item2) => item2.id !== item.id)) }}
                        disabled={editMode}
                      >
                        <AiOutlineClose className="align-self-center" />
                      </Button>
                      {/* <Button variant="outline-dark" >delete</Button> */}
                    </Col>
                  </Row>
                ))
              }
            </Col>
            {
              !editMode && (
                <div className="col-3 ">
                  <Dropdown onSelect={(e) => { setCapacity([...capacity, JSON.parse(e)]) }}  >
                    <Dropdown.Toggle as={CustomToggle} >
                      Agregar Participante
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {
                        filterListUser().map((item) => (
                          <Dropdown.Item key={item.id} eventKey={JSON.stringify(item)} disabled={(currentAvailability - capacity.length) === 0}>
                            {`${item.nombres} ${item.apellidos} - ${item.documento}`}
                          </Dropdown.Item>
                        ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )
            }
          </Row>
          {repeat && (
            <Row className="mb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Fecha Final" className="mb">
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    placeholder="Fecha"
                    min={formatDate(day)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La fecha final de reserva es obligatoria.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label="Frecuencia" className="mb">
                  <Form.Select
                    onChange={(e) => setRepeatKind(e.target.value)}
                    value={repeatKind}
                    placeholder="Frecuencia"
                    required
                  >
                    <option defaultValue></option>
                    <option value="daily">Diaria</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    La frecuencia es obligatoria.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
          )}
          {editMode && (
            <Row className="mb-2">
              <Col>
                <Form.Group>
                  <FloatingLabel label="Observación" className="mb">
                    <Form.Control
                      as="textarea"
                      value={formatObservation()}
                      placeholder="Observación"
                      style={{ height: "104px" }}
                      disabled
                    />
                  </FloatingLabel>
                </Form.Group>
              </Col>
              <Col>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <FloatingLabel label="Observación" className="mb">
                      <Form.Control
                        type="text"
                        value={observation}
                        onChange={(e) => {
                          setObservation(e.target.value);
                        }}
                        placeholder="Observación"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    className="btn rounded-full"
                    variant="outline-primary"
                    onClick={addObservation}
                  >
                    {" "}
                    Agregar Comentario
                  </Button>
                </Row>
              </Col>
            </Row>
          )}
          <Modal.Footer>
            <Button
              className="btn rounded-full"
              variant="outline-dark"
              onClick={handleClose}
            >
              Salir
            </Button>
            {editMode ? (
              <>
                <Button
                  className="btn rounded-full"
                  variant="outline-success"
                  type="submit"
                >
                  Actualizar
                </Button>
              </>
            ) : (
              <Button
                className="btn rounded-full"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAppointmantSpotsInfo;
